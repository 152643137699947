import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiCode, FiHelpCircle, FiMonitor, FiServer} from "react-icons/fi";
import {useLocation} from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from "../LandingPages/components/Footer";


import Helmet from "../component/common/Helmet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Hero from "./components/Hero";
import Bullet from "./components/Bullet";
import Platform from "./components/Platform";
import {useDispatch} from "react-redux";
import {userActions} from "../store/user";
import Brand from "../elements/Brand";


const capitalize = string => {
    if(string){
        const stringList = string.split(" ");
        const finalString = stringList.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return finalString
    } else {
        return undefined
    }
}


const ArcGISEnterpriseDeployment = () => {
    const [promoActive, setPromoActive] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const query = useQuery();
    const keyword = capitalize(query.get('keyword'));
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', keyword ? keyword : 'ArcGIS Enterprise']);
    _paq.push(['trackPageView']);

    // Remove third party tracking params
    useEffect(() => {
        window.history.replaceState(null, `${keyword ? 'ArcGIS Enterprise' : keyword} | Deployment & Maintenance | Kuala Lumpur`, keyword ? `/arcgis-enterprise-deployment${keyword&&`?keyword=${keyword}`}` : '/arcgis-enterprise-deployment')
    }, [keyword]);

    useEffect(() => {
        if(keyword){
            dispatch(userActions.setKeyword(keyword));
        }
    }, [keyword, dispatch])
    useEffect(() => {
        dispatch(userActions.setCampaign('ArcGIS Enterprise Leads'));
    }, [dispatch])

    const ServiceList = [
        {
            icon: <FiServer/>,
            title: 'Deployment',
            description: 'On premise effective and efficient deployment around Malaysia. Secure and trusted.'
        },
        {
            icon: <FiMonitor/>,
            title: 'Maintenance',
            description: 'All systems require levels of maintenance, ZedPro provides routine security & feature updates.'
        },
        {
            icon: <FiCode/>,
            title: 'Development',
            description: 'Custom dashboard, webapp, and mobile app development is available to further enhance capabilities.'
        },
        {
            icon: <FiHelpCircle/>,
            title: 'Dedicated Support',
            description: 'While things rarely go wrong, they always can, our team is ready to assist if they ever do.'
        },
    ]
    return (
        <div className="active-dark">
            <Helmet pageTitle={keyword ? keyword : 'ArcGIS Enterprise'}/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"
                    isLandingPage={keyword}/>

            {/* Start Hero Area */}
            <Hero
                page={'arcgis'}
                promoActive={promoActive}
                setPromoActive={setPromoActive}
                keyword={keyword}
                title='ArcGIS Enterprise Deployment & Development Services'
                price={10000}
                description='Take your business to the next level, Analyze and display your geographic data using the worlds No. 1 enterprise GIS solutions.'
                hook='High Availability Deployments are available.'
            />
            {/* End Hero Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--1" style={{
                padding: '100px 50px'
            }}>
                {/*<Trusted/>*/}
                <div className="section-title" style={{
                    display: "flex",
                    justifyContent: 'center',
                    marginBottom: 50
                }}>
                    <span className="subtitle subtitleMod--medium">DEPLOYED AT</span>
                </div>
                <Brand brandStyle="branstyle--2" brands={[
                    {image:"/assets/images/brand/brand-01.png",altText:"PDRM"},
                    {image:"/assets/images/brand/brand-02.png",altText:"SUK Kedah"},
                    {image:"/assets/images/brand/brand-03.png",altText:"Lembaga Getah Malaysia"},
                    // {image:"/assets/images/brand/brand-04.png",altText:"U Mobile"},
                    // {image:"/assets/images/brand/brand-05.png",altText:"Geoinfo Services Sdn Bhd"},
                    // {image:"/assets/images/brand/brand-06.png",altText:"Handal"},
                ]}/>
            </div>
            {/* End Service Area  */}

            {/*        /!* Start Service Area *!/*/}
            <div className="service-wrapper service-white" style={{
                padding: '100px 50px'
            }}>
                <Services
                    serviceList={ServiceList}
                    title='One Stop Solution'
                />
            </div>
            {/*        /!* End Service Area *!/*/}
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Bullet
                    image='/assets/images/landing/arcgis-01.png'
                    title='Who is ArcGIS Enterprise for?'
                    subTitle='Designed for those who require industry leading mapping and analytical infrastructure, such as:'
                    list={['Law Enforcement', 'Land Offices', 'Oil & Gas', 'Asset Management Companies']}
                />
            </div>
            <div
                className="align-items-center bg_color--5"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Platform
                    image='/assets/images/landing/arcgis-02.jpg'
                    title='What is ArcGIS Enterprise?'
                    description='ArcGIS Enterprise is a software system for GIS, powering analytics, visualization, mapping and data management. It is the main infrastructure of the esri suite of applications. Giving you total control over your deployment and the data stored within.'
                    ctaTitle='More about ArcGIS'
                    ctaLink='https://enterprise.arcgis.com/en/'
                />
            </div>
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
                id='contact'
            >
                <Contact
                    title='Schedule a free consultation'
                    description={
                        <span>
                        Jump on a quick zoom call with us, and we will discuss your requirements and propose a few solutions.
                        Upon confirmation we will start deploying your enterprise software based upon the pre-agreed scope and specifications.
                        <br/> <br/>
                        Then within the agreed upon timeframe we will prepare for handover and training for your staff.
                        Lastly a quick session to summarise the deployment and answer any questions you or your company may have.
                    </span>
                    }
                    phoneTrigger='AW-620745259/nRKICKL49vACEKuk_6cC'
                    emailTrigger='AW-620745259/o4mRCNeVzPACEKuk_6cC'
                    formTrigger='AW-620745259/IyNECPuP5OUCEKuk_6cC'
                    isLandingPage={keyword}
                />
            </div>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer
                ctaTitle={`From A to Z${promoActive ? ' from only RM20,000' : ''}`}
            />

        </div>
    )
}
export default ArcGISEnterpriseDeployment;
