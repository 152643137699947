import React from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {HashLink} from "react-router-hash-link";
const Header = ({isLandingPage, logo, color='default-color'}) => {
    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    const CloseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    var elements = document.querySelectorAll('.has-droupdown > a');
    for(var i in elements) {
        if(elements.hasOwnProperty(i)) {
            elements[i].onclick = function() {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }
    // const { logo, color='default-color' } = this.props;
    let logoUrl;
    if(logo === 'light'){
        logoUrl = <img src="/assets/images/logo/logo-light.png" alt="ZedPro" style={{maxHeight: isLandingPage ? 60 : 30}}/>;
    }else if(logo === 'dark'){
        logoUrl = <img src="/assets/images/logo/logo-transparent.png" alt="ZedPro" style={{maxHeight: isLandingPage ? 60 : 30}}/>;
    }else if(logo === 'symbol-dark'){
        logoUrl = <img src="/assets/images/logo/logo-transparent.png" alt="ZedPro" style={{maxHeight: isLandingPage ? 60 : 30}}/>;
    }else if(logo === 'symbol-light'){
        logoUrl = <img src="/assets/images/logo/logo-transparent.png" alt="ZedPro" style={{maxHeight: isLandingPage ? 60 : 30}}/>;
    }else{
        logoUrl = <img src="/assets/images/logo/logo-transparent.png" alt="ZedPro" style={{maxHeight: isLandingPage ? 60 : 30}}/>;
    }
    return (
        <header className={`header-area formobile-menu header--transparent ${color}`}>
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            {logoUrl}
                        </a>
                    </div>
                </div>
                {
                    !isLandingPage && (
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <ul className="mainmenu">
                                    <li><Link to="/">Home</Link>
                                    </li>
                                    <li><Link to="/about" >About</Link></li>
                                    <li className="has-droupdown"><HashLink to="#" >Services</HashLink>
                                        <ul className="submenu">
                                            <li><Link to="/website-development">Website Development</Link></li>
                                            <li><Link to="/webapp-development">Web App Development</Link></li>
                                            <li><Link to="/mobileapp-development">Mobile App Development</Link></li>
                                            <li><Link to="/arcgis-enterprise-deployment">ArcGIS Enterprise Deployment</Link></li>
                                            <li><Link to="/server-deployment">Server Deployment</Link></li>
                                            <li><Link to="/script-based-automation">Script Based Automation</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                    <li><Link to="/contact" >Contact</Link></li>
                                </ul>
                            </nav>
                            {/* <div className="header-btn">
                            <a className="btn-default btn-border btn-opacity" target="_blank" href="https://themeforest.net/checkout/from_item/31405042?license=regular">
                                <span>Buy Now</span>
                            </a>
                        </div> */}
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                                <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={CloseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    )
                }
            </div>
        </header>
    )
}
export default Header;
