import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
// import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Blog extends Component{
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Blog']);
            _paq.push(['trackPageView']);
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Blog' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" logo='light'/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Blog'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <BlogList />
                        {/* <div className="row mt--60"> */}
                            {/* <div className="col-lg-12"> */}
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
                {/* End Blog Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Blog;
