import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class GoogleRankingFactorsThatYouNeedToKnow extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Google ranking factors that you need to know']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Google ranking factors that you need to know' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Google ranking factors that <br /> you need to know</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 12, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h2>Google ranking factors</h2>
                                    <p>There are many google ranking factors, below are the main breakdown of the key factors you need to know.</p>
                                    <h2><strong>On-page optimization</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/clement-h-95YRwf6CNw8-unsplash.jpg" alt="Credit Clement"/>
                                    </div>
                                    <h4><strong>What can you do?</strong></h4>
                                    <p>There are several things that can be done on-page, to maximize your web pages search engine optimization. They range from The design of your site, how fast it loads and, its responsiveness on different size screens. How mobile-friendly it is, and the way content is arranged also plays an important part.</p>
                                    <h4><strong>Does your website design matter?</strong></h4>
                                    <p>The way your website is designed and structured plays a role in how well it will rank within search engines. The spacing of elements, font size, readability, and the margins between objects are important. As Google algorithms consider them when ranking your site in the search engines.</p>
                                    <h4><strong>Does speed improve a website's google ranking?</strong></h4>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/slower-page-load-speed-equals-higher-bounce-rates.png" alt="Credit Backlinko"/>
                                    </div>
                                    <p>These days patience is a premium, no one likes to wait long for websites to load. Your page might not rank as well for competitive keywords if it has high load times. High load times also lead to a higher bounce rate. <a href="https://backlinko.com/hub/seo/bounce-rate" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Backlinko</a> has a great article about bounce rates and how to improve them. The general idea is that there is a correlation between quick websites, bounce rates, and its ranking within google.</p>
                                    <h4><strong>Responsiveness matters.</strong></h4>
                                    <p>There isn't a standardized screen size or resolution for devices that are used to browse the web. Users will undoubtedly be using a plethora of devices ranging from desktops with 4K screens to small mobile screens. Websites need to be responsive so content does not appear skewed and distorted. The text also should not be too small to read or cut off at the edge of the screen.</p>
                                    <h4><strong>Mobile friendliness.</strong></h4>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/gilles-lambert-pb_lF8VWaPU-unsplash.jpg" alt="Credit Giles"/>
                                    </div>
                                    <p>Based on Google’s official statements, they state that search queries from mobile devices account for more than 50 percent of searches. Search engines and especially Google tend to rank websites with the philosophy of mobile-first. Because of this, your website must be mobile-friendly. Websites without a responsive mobile mode will not be ranked as highly as their responsive competitors.</p>
                                    <h4><strong>Image alt tags</strong></h4>
                                    <p>Alt tags are a textual description of an image. Also known as “alt attributes”, they serve two main functions. Firstly they tell Google what the image is for and its relevance within the context of your page. They also help search engines index the image, which boosts your search engine optimization. This will help your website's ranking in Google. Secondly, they are an accessibility feature as they will display when the image cannot be loaded. This will inform visitors about the image that was supposed to load and insert some context.</p>
                                    <h4><strong>Keywords in appropriate locations.</strong></h4>
                                    <p>When trying to rank for specific keywords, it goes without saying that they must be included within your content. They must also make sense and be relevant. You would not try to rank for the keyword “Cupcakes” but write an article about “Chicken soup” would you? The keyword should be placed within the title, h1, and h2 headings, as well as within the first paragraphs. Be sure to intelligently place the keywords and don’t just dump them there. Your keyword should also be within your pages URL slug.</p>
                                    <h4><strong>Outbound Links</strong></h4>
                                    <p>Links that take visitors away from your page are called outbound links. These links can go to references or further information about something you are talking about. Outbound links are important as they build trust and show the search engine that you are a legitimate site. They demonstrate that you have referenced your content which helps give your content authority. This is similar to when sources are referenced in academia.</p>
                                    <h2><strong>Good content</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/austin-distel-Hg3BHX6U5jg-unsplash.jpg" alt="Credit Austin"/>
                                    </div>
                                    <h4><strong>What makes good content?</strong></h4>
                                    <p>When writing content for your website, it is important to follow a specific set of guidelines. Your content must be relevant to your niche. For example, if your site is about cooking, your content should reflect that. You should not have content that is irrelevant like an article about house painting in a cooking blog. Your content should also be original and fresh. You must never commit plagiarism by copy and pasting content from other sites into yours no matter how tempting that may be. Your content should also be easy to understand and well written. Topics must be explained at the comprehension level of a teenager.</p>
                                    <h4><strong>How much content do you need?</strong></h4>
                                    <p>Good content also has volume. Apart from quality, your content requires a certain level of quantity as well. Pages with low word counts will rank lower than pages that have higher word counts. You should try to get a consistent length for each page of about 500 words on average. However, for some competitive keywords, you may need to write as much as 2000 words. For example, if you are writing a comprehensive guide about a topic, 500 words are just not enough.</p>
                                    <h4><strong>Up to date</strong></h4>
                                    <p>It is vital that your website has up to date content, as Google and other search engines priorities new content. No one wants to spend time reading something that was accurate a few years ago. Be sure to go over your content from time to time, and update them, either yearly or when things change. Updating your content is an excellent way to improve organic search traffic on an old post.</p>
                                    <h4><strong>Why must you consistently post new content?</strong></h4>
                                    <p>Websites that regularly post and update their content will improve their ranking on Google. Fresh up to date content lets Google know your site is not stale and outdated. It helps build your domain authority within Google’s algorithms, which in turn improves your ranking. New content also brings with it new keywords that can be targeted. The more keywords you can rank for, the more organic visitors you can attract to your site. Once you have built a solid trust worthy site, ranking up fast within search results will take days instead of months.</p>
                                    <h2><strong>Good Back-links are an important Google ranking factor</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/sandy-millar-OzyY3C8zVU8-unsplash.jpg" alt="Credit Sandy"/>
                                    </div>
                                    <h4><strong>What are back-links?</strong></h4>
                                    <p>These are links that come from other websites that link to your website from another domain. Search engines like Google see this connection and view it as a referral of sorts. It's like when someone in real life puts in a good word on your behalf. Not all back-links are created equal, the value of a back-link depends heavily on how much domain authority the referring domain has. Think of it like getting a good word put in for you from say the CEO of a company vs say someone who is only a manager.</p>
                                    <h4><strong>Why do they help?</strong></h4>
                                    <p>Back-links are important as they let the search engine know if your website has content that other websites willingly refer to. Content that is deemed valuable, content that people would want to see. Pages with strong back-links will outperform those with weaker ones. The quantity and quality of those back-links play a vital role in how Google and other search engines determine where your website should rank in their organic search results. Back-links also let Google know that your website is a legitimate site and not spam.</p>
                                    <h3><em><strong>How to get them?</strong></em></h3>
                                    <p>There are many ways to get back-links, some legitimate, and some not so much. While it may be tempting to go the quick and easy route, it could be damaging in the long run. The easiest way to get safe back-links initially is to register your business and website into social media. Business directories are also a great place as they often allow you to add a website. Private blog networks are another way to go. However, you are better off with Guest blog posts on legitimate websites. You may also get some back-links organically by creating awesome content that other websites and blogs would naturally refer to.</p>
                                    <h4><strong>Social Media and Business Directories</strong></h4>
                                    <p>When launching a new website, especially on a fresh domain name, Google places you into a sandbox. New domain and by extension websites have little to no trust and authority within cyberspace. It is important to add your website and share its URL in as many social platforms and directories as possible. This informs Google and other search engines that your new website is serious, and not some random spam site. Adding these back-links will help you get out of the sandbox and into Google faster. Social media pages and business directory sites are also a great way to increase exposure and organic traffic to your web page.</p>
                                    <h3><em><strong>White hat vs Black hat</strong></em></h3>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/bill-oxford-OXGhu60NwxU-unsplash.jpg" alt="Credit Bill"/>
                                    </div>
                                    <p>As there are legitimate(white hat) and illegitimate(black hat) methods of gaining back-links it is important to understand the difference. Legitimate back-links include organic referrals, source references, and guest blog posts. Illegitimate back-links are links purchased from private blog networks. They exist solely to artificially prop up other sites. When these types of black hat links are discovered by Google, your website will be penalized. It is important in the long run to build credibility using white hat back-links.</p>
                                    <h4><strong>Guest posting</strong></h4>
                                    <p>Guest posting is essentially creating content and submitting it into actual websites owned by businesses and real blogs. These sites have the discretion to reject your posts. You may have to try a few different pitches to get the privilege of posting your content on their website. But when you are successful, you will end up with a solid back-link to your website. The more guest blog posts you have on other websites, the better the ranking of your website will be on google.</p>
                                    <h4><strong>Private Blog Networks</strong></h4>
                                    <p>PBN’s are a network of artificially boosted blogs who’s sole purpose is to link to sites that pay them. They are not actual legitimate websites owned by legitimate businesses. This is generally a bad idea, as your competitors may complain and you may be discovered by other means. While they are effective in the short run, in the long run, your website will end up being penalized. Your search engine rankings could suffer greatly, and then you will face a hard time trying to regain the trust of search engines.</p>
                                    <h3><em><strong>Domain Authority</strong></em></h3>
                                    <p><a href="https://moz.com/learn/seo/domain-authority"><u>Domain authority</u></a> is a score that predicts how well websites will rank, it ranges from 1 to 100. The higher your score naturally would mean the better you would be able to rank. This score was developed by MOZ and is not used by Google when ranking your website in any way. However is it a useful metric to view how well your site is doing.</p>
                                    <blockquote>“Domain Authority is calculated by evaluating multiple factors, including linking root domains and the number of total links, into a single DA score. This score can then be used when comparing websites or tracking the "ranking strength" of a website over time.”</blockquote>
                                    <cite>According to <a href="https://moz.com/learn/seo/domain-authority" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">MOZ</a></cite>
                                    <p>You can check your Domain Authority using MOZ’s <a href="https://moz.com/link-explorer#index"><u>Link Explorer</u></a>.</p>
                                    <h3><em><strong>Nofollow vs Dofollow link</strong></em></h3>
                                    <p>Nofollow links tell web crawlers to ignore that link. Examples of Nofollow links include but are not limited to blog comments, social media, and links on forums. They exist to prevent spam, as spammers often love to create their own back-links that point to their own websites in comments and other places like that. Nofollow link tags are also applied to sponsored or paid links.</p>
                                    <p>Dofollow links indicate that you want search engines to continue and check out the link. Dofollow links are normal links within websites. They indicate that a website is voluntary including a link to an external site. This will pass along the delicious link juice to the target website, and increase your website's domain authority. A website with high domain authority will improve its ranking on google.</p>
                                    <p>Nofollow links deffer from Dofollow links in that it does not pass on any credibility or trust from the linking domain. However some Nofollow links do have beneficial attributes, they help build trust that your website is real, and not spam. A spam website would not create for itself, social media accounts. It would not register itself in business directories. It is important to have both, as together they play a vital part, in your website's credibility in the eyes of search engines.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default GoogleRankingFactorsThatYouNeedToKnow;