import React, { Component } from "react";
import {FiCast, FiLayers, FiMonitor, FiMap, FiCpu, FiCode} from "react-icons/fi";
import {Link} from "react-router-dom";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Strategy & Consulting',
        description: 'Our consultants are happy to guide you through the tech world and to your objectives.',
        url: '/#',
    },
    {
        icon: <FiLayers />,
        title: 'Webapp & Software Development',
        description: 'We are proficient with many website platforms, like ReactJS, Django, and wordpress.',
        url: '/webapp-development',
    },
    {
        icon: <FiMap />,
        title: 'Geographic Information Systems',
        description: 'Deployment and development of both enterprise and open source geographic solutions.',
        url: '/arcgis-enterprise-deployment',
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile Responsive Development',
        description: 'More than half of all internet users are on mobile devices compared to desktops.',
        url: '/mobileapp-development',
    },
    {
        icon: <FiCpu />,
        title: 'Server Deployment & Maintenance',
        description: 'More than capable to help with any type of server deployment and routine maintenance.',
        url: '/server-deployment',
    },
    {
        icon: <FiCode />,
        title: 'Script Based Automation',
        description: 'Streamline your workflow by automating basic or high level tasks using scripts.',
        url: '/script-based-automation',
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services provide for you.',
        description = 'We pride ourselves at being a one-stop solution for our clients, </br> No matter what they need we do our bests to fulfil them.',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                         <Link to={val.url}>
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                         </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
