import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class InstallOnlyOfficeDocumentServerWithVestaCP extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Install OnlyOffice Document Server with VestaCP']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Install OnlyOffice Document Server with VestaCP' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Install OnlyOffice Document Server <br/> with VestaCP</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Aug 17, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>So you have installed VestaCP and everything is going great, you even installed Nextcloud and you are now trying to install Onlyoffice Document Server with VestaCP but are not having any luck. If that sounds familiar it is because I tried to do the same thing. But every time I would search the web for a solution there wasn't really anything guide I could follow specifically to get it working with VestaCP.</p>
                                        <p>Whenever I would try to install OnlyOffice alongside VestaCP things would not go smoothly and I would always have to restore my VPS from a backup. For a while, I did what anyone would do and just settled for running OnlyOffice on a separate VPS. But come on! that's just a waste of money, having a separate VPS for something you don't even use all the time. So finally I decided to get to it, dive deep into the code base, and find a solution.</p>
                                        <h4>You will need three things:</h4>
                                        <ul><li><strong>Docker</strong></li><li><strong>A Sub-Domain</strong></li><li><strong>Custom VestaCP template (and of course VestaCP)</strong></li></ul>
                                        <p>The installation process is relatively easy, first, you install Docker on your system, then you run the docker script for OnlyOffice, followed by connecting the sub-domain you want to park OnlyOffice to. That's about it and you are good to go. That is literally how you install Onlyoffice Document Server with VestaCP, simple and without a fuss. So now let's go through it step by step.</p>
                                        <h3>Install Docker</h3>
                                        <p>Installing Docker Community edition is simple and requires you to enter the respective install commands on your system.</p>
                                        <h5>Ubuntu</h5>
                                        <p>If you are using Ubuntu you will have to utilize the APT command to install Docker. Before you do so please make sure you update your system to the latest packages to avoid any potential complications.</p>
                                        <pre ><code>sudo apt update &amp;&amp; sudo apt upgrade -y</code></pre>
                                        <p>The docker engine does not come in the default repositories on Ubuntu so to install docker we will first have to set up the official docker repositories. Only then can we begin installing Docker on our system.</p>
                                        <p>The Docker Repositories use HTTPS so we will need to install some packages to allow APT to function over HTTPS:</p>
                                        <pre ><code>sudo apt-get install apt-transport-https ca-certificates curl gnupg-agent software-properties-common -y</code></pre>
                                        <p>Next we are going to add the official GPG key for the Docker repository:</p>
                                        <pre ><code>curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -</code></pre>
                                        <p>Now the follow command will set up the official stable Docker repository:</p>
                                        <pre ><code>sudo add-apt-repository "deb &#91;arch=amd64] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable"</code></pre>
                                        <p>Moving on its time to install the Docker engine, as per best practice we will update the system repositories beforehand:</p>
                                        <pre ><code>sudo apt-get update
                                        sudo apt-get install docker-ce docker-ce-cli containerd.io -y</code></pre>
                                        <p>Once the installation has completed let us verify that the docker engine has installed successfully:</p>
                                        <pre ><code>sudo docker run hello-world</code></pre>
                                        <p>If everything has gone correctly you should get the following output:</p>
                                        <pre>{`
onlyoffice@localhost:~$ sudo docker run hello-world
Unable to find image 'hello-world:latest' locally
latest: Pulling from library/hello-world
0e03bdcc26d7: Pull complete
Digest: sha256:49a1c8800c94df04e9658809b006fd8a686cab8028d33cfba2cc049724254202
Status: Downloaded newer image for hello-world:latest

Hello from Docker!
This message shows that your installation appears to be working correctly.

To generate this message, Docker took the following steps:
 1. The Docker client contacted the Docker daemon.
 2. The Docker daemon pulled the "hello-world" image from the Docker Hub.
    (amd64)
 3. The Docker daemon created a new container from that image which runs the
    executable that produces the output you are currently reading.
 4. The Docker daemon streamed that output to the Docker client, which sent it
    to your terminal.

To try something more ambitious, you can run an Ubuntu container with:
 $ docker run -it ubuntu bash

Share images, automate workflows, and more with a free Docker ID:
 https://hub.docker.com/

For more examples and ideas, visit:
 https://docs.docker.com/get-started/
                                        `}</pre>
                                        <p>On Ubuntu to show the list of running dockers you can use the command below:</p>
                                        <pre ><code>sudo docker container ls</code></pre>
                                        <p>For now, the command should return an empty list as nothing is currently running. </p>
                                        <p>Congratulations you have successfully installed docker on your Ubuntu Installation. In the next step, we shall get the OnlyOffice Docker up and running.</p>
                                        <h5>CentOS</h5>
                                        <p>If you are going with CentOS we shall be using a slightly different variation of commands. Contrary to the APT package manager, CentOS will need you to use the YUM package manager.</p>
                                        <p>Before you do anything please make sure you update your system to the latest packages to avoid any potential complications.</p>
                                        <pre ><code>sudo yum update -y</code></pre>
                                        <p>Just like ubuntu, the docker engine does not come in the default repositories so to install docker we will first have to set up the official docker repositories. Only then can we begin installing Docker on our system.</p>
                                        <p>You will need the package <code>yum-utils</code> which will provide the <code>yum-config-manager</code>, a utility that will allow you to add the repository we will be needing to install Docker.</p>
                                        <pre ><code>sudo yum install -y yum-utils</code></pre>
                                        <p>Now the follow command will set up the official stable Docker repository:</p>
                                        <pre ><code>sudo yum-config-manager --add-repo https://download.docker.com/linux/centos/docker-ce.repo</code></pre>
                                        <p>Moving on its time to install the Docker engine, as per best practice we will update the system repositories beforehand:</p>
                                        <pre ><code>sudo yum update -y
                                        sudo yum install docker-ce docker-ce-cli containerd.io -y</code></pre>
                                        <h6>If you face any issue regarding containerd.io and get the following error message as I did when I tried to install Docker on CentOS 8 you will need to install containerd.io manually as per the solution provided by <a href="https://vexpose.blog/" target="_blank" rel="noreferrer noopener">vExpose</a>:</h6>
                                        <pre>{`
Error:
Problem: package docker-ce-3:19.03.12-3.el7.x86_64 requires containerd.io >= 1.2.2-3, but none of the providers can be installed
 - cannot install the best candidate for the job
 - package containerd.io-1.2.10-3.2.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.13-3.1.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.13-3.2.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.2-3.3.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.2-3.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.4-3.1.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.5-3.1.el7.x86_64 is filtered out by modular filtering
 - package containerd.io-1.2.6-3.3.el7.x86_64 is filtered out by modular filtering
                                        `}</pre>
                                        <p>To manually install the containard.io package use the following command listed below:</p>
                                        <pre ><code>sudo yum install -y https://download.docker.com/linux/centos/7/x86_64/stable/Packages/containerd.io-1.2.6-3.3.el7.x86_64.rpm</code></pre>
                                        <p>Then try once again to install the Docker packages, this time omitting the containard.io package:</p>
                                        <pre ><code>sudo yum install docker-ce docker-ce-cli -y</code></pre>
                                        <p>Once the installation has completed we will need to start the Docker service:</p>
                                        <pre ><code>sudo systemctl start docker
                                        sudo systemctl enable docker</code></pre>
                                        <p>Now let us verify that the docker engine has installed successfully:</p>
                                        <pre ><code>sudo docker run hello-world</code></pre>
                                        <p>If everything has gone correctly you should get the following output:</p>
                                        <pre >{`
[onlyoffice@li1758-184 ~]$ sudo docker run hello-world
Unable to find image 'hello-world:latest' locally
latest: Pulling from library/hello-world
0e03bdcc26d7: Pull complete
Digest: sha256:49a1c8800c94df04e9658809b006fd8a686cab8028d33cfba2cc049724254202
Status: Downloaded newer image for hello-world:latest

Hello from Docker!
This message shows that your installation appears to be working correctly.

To generate this message, Docker took the following steps:
 1. The Docker client contacted the Docker daemon.
 2. The Docker daemon pulled the "hello-world" image from the Docker Hub.
    (amd64)
 3. The Docker daemon created a new container from that image which runs the
    executable that produces the output you are currently reading.
 4. The Docker daemon streamed that output to the Docker client, which sent it
    to your terminal.

To try something more ambitious, you can run an Ubuntu container with:
 $ docker run -it ubuntu bash

Share images, automate workflows, and more with a free Docker ID:
 https://hub.docker.com/

For more examples and ideas, visit:
 https://docs.docker.com/get-started/
                                        `}</pre>
                                        <p>On CentOS to show the list of running dockers you can use the command below:</p>
                                        <pre ><code>sudo docker container ls</code></pre>
                                        <p>For now, the command should return an empty list as nothing is currently running. </p>
                                        <p>Congratulations you have successfully installed docker on your Ubuntu Installation. In the next step, we shall get the OnlyOffice Docker up and running.</p>
                                        <h3>Install OnlyOffice Document Server with VestaCP</h3>
                                        <p>Now that we have got the Docker engine installed and running properly, its time to set up the OnlyOffice Document Server. Below are the minimum system requirements set out in the official documentation although I'm sure you could try to get by with less.</p>
                                        <h4>System Requirements</h4>
                                        <ul><li>CPU&nbsp;dual-core&nbsp;<strong>2 GHz</strong>&nbsp;or better</li><li>RAM&nbsp;<strong>2 GB</strong>&nbsp;or more</li><li>HDD&nbsp;at least&nbsp;<strong>40 GB</strong>&nbsp;of free space</li><li>Additional requirements&nbsp;at least&nbsp;<strong>4 GB</strong>&nbsp;of swap</li><li>OS&nbsp;<strong>amd64</strong>&nbsp;Linux distribution with kernel version&nbsp;<strong>3.10</strong>&nbsp;or later</li><li>Additional requirements<ul><li><strong>Docker</strong>: version&nbsp;<strong>1.10</strong>&nbsp;or later</li></ul></li></ul>
                                        <p>Moving forward there are two ways to set up the docker. You can set it up with storage inside the containers or with storage outside the containers. Naturally you want your storage to have persistence and you will want to set it up like I did with the data outside of the container.</p>
                                        <h5>The data are stored in their own specific directories which we will map to within the docker container itself:</h5>
                                        <ul><li><code>/var/log/onlyoffice</code>&nbsp;<strong>Document Server</strong>&nbsp;logs</li><li><code>/var/www/onlyoffice/Data</code> certificates</li><li><code>/var/lib/onlyoffice</code> file cache</li><li><code>/var/lib/postgresql</code>&nbsp;database</li></ul>
                                        <p>Since we will be using Nginx as a reverse proxy we won't be setting up the docker to run using HTTPS. Now using the template I have set up below which is set up to connect using the port 8181 we will map the custom port to the local container HTTP port of 80. The code is below; if you would like to change the port mapping remember to change them in the VestaCP template as well:</p>
                                        <pre><code>{`
sudo docker run -i -t -d -p 8181:80 --restart=always \\
    -v /app/onlyoffice/DocumentServer/logs:/var/log/onlyoffice  \\
    -v /app/onlyoffice/DocumentServer/data:/var/www/onlyoffice/Data  \\
    -v /app/onlyoffice/DocumentServer/lib:/var/lib/onlyoffice \\
    -v /app/onlyoffice/DocumentServer/db:/var/lib/postgresql  onlyoffice/documentserver
                                        `}</code></pre>
                                        <p>The Docker engine should now begin to pull the container from the official Docker Hub. This should take a few minutes if you are running this for the first time. You should the following output if you are running it for the first time:</p>
                                        <pre >{`
Unable to find image 'onlyoffice/documentserver:latest' locally
latest: Pulling from onlyoffice/documentserver
23884877105a: Pull complete
bc38caa0f5b9: Pull complete
2910811b6c42: Pull complete
36505266dcc6: Pull complete
73f11410c785: Pull complete
0b794493f513: Pull complete
633e8270f79c: Pull complete
6d05fcc39b66: Pull complete
Digest: sha256:4e667e43243e283c72700420b5a6111506469403d06c52b6d458129837a8737f
Status: Downloaded newer image for onlyoffice/documentserver:latest
088e53408e8c6badb8525db815bd1eec0bf6ea8fafa86b1ef188260a4f648b52
                                        `}</pre>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/onlyoffice.jpg" alt="OnlyOffice"/>
                                            <p>To check if the server is running go to your IP address under the chosen port. (example http://ipaddress:8181)</p>
                                        </div>
                                        <p className="mt--40">The container is set to automatically restart if it is ever stopped. If you would like to manually stop it you can do so with the command below:</p>
                                        <pre ><code>sudo docker stop container_name</code></pre>
                                        <p>The name of your container will be the last entry on your right when you run the following command to list running docker containers:</p>
                                        <pre ><code>sudo docker container ls</code></pre>
                                        <p>If you would like to run the container again just rerun the original command you used to run the container.</p>
                                        <h3>Install VestaCP custom template</h3>
                                        <p>Now that you have installed OnlyOffice Document Server, you can move on to the next step. Since you probably want a secure connection to it via HTTPS, we will need to generate an SSL certificate and connect it to a domain. You don't want any of the hassles of a self-signed certificate.</p>
                                        <p>We shall let VestaCP handle all of it, but how you ask? By using VestaCP Nginx templates. To do so we will need a special custom code to properly be able to run the document server.</p>
                                        <p>There are two ways you can do this, the easy way or the manual way which requires a few more steps.</p>
                                        <p>First enter into the Nginx folder that is located within the VestaCP templates folder:</p>
                                        <pre ><code>cd /usr/local/vesta/data/templates/web/nginx</code></pre>
                                        <h3>Download the template files</h3>
                                        <p>Download and unzip the template files to your VestaCP Nginx templates folder:</p>
                                        <pre ><code>sudo wget -4 https://zedpro.me/vestacp/templates/onlyoffice.zip && sudo unzip onlyoffice.zip</code></pre>
                                        <h3>Manually Writing the template files</h3>
                                        <p>If you would like to manually create those files, you can copy and paste the template code below. You can even modify the code to better suit your needs. You will need to create two files in the Nginx template folder;</p>
                                        <p>Below is the custom code for the respective Nginx templates will all the necessary configurations to securely reverse proxy the OnlyOffice Document Server Docker container to your chosen subdomain:</p>
                                        <h4><strong>.tpl file</strong></h4>
                                        <pre>{`
server {
    listen      %ip%:%proxy_port%;
    server_name %domain_idn% %alias_idn%;
    location / {
        rewrite ^(.*) https://%domain_idn%$1 permanent;
    }
include %home%/%user%/conf/web/*nginx.%domain_idn%.conf_letsencrypt;
} 
                                        `}</pre>
                                        <h4>.stpl file</h4>
                                        <pre>{`
map $http_host $this_host {
    "" $host;
    default $http_host;
}

map $http_x_forwarded_proto $the_scheme {
     default $http_x_forwarded_proto;
     "" $scheme;
}

map $http_x_forwarded_host $the_host {
    default $http_x_forwarded_host;
    "" $this_host;
}

map $http_upgrade $proxy_connection {
  default upgrade;
  "" close;
}

proxy_set_header Upgrade $http_upgrade;
proxy_set_header Connection $proxy_connection;
proxy_set_header X-Forwarded-Host $the_host;
proxy_set_header X-Forwarded-Proto $the_scheme;
proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;

server {
    listen      %ip%:%proxy_ssl_port% http2;
    server_name %domain_idn% %alias_idn%;
    ssl         on;
    ssl_certificate      %ssl_pem%;
    ssl_certificate_key  %ssl_key%;
    ssl_verify_client off;
    error_log  /var/log/%web_system%/domains/%domain%.error.log error;


  ssl_ciphers "EECDH+AESGCM:EDH+AESGCM:AES256+EECDH:AES256+EDH";

  ssl_protocols  TLSv1 TLSv1.1 TLSv1.2;
  ssl_session_cache  builtin:1000  shared:SSL:10m;

  ssl_prefer_server_ciphers   on;

  add_header Strict-Transport-Security max-age=31536000;
  add_header X-Content-Type-Options nosniff;


    location / {
        proxy_pass      http://localhost:8181;
        proxy_http_version 1.1;
        location ~* ^.+\\.(%proxy_extentions%)$ {
            root           %sdocroot%;
            access_log     /var/log/%web_system%/domains/%domain%.log combined;
            access_log     /var/log/%web_system%/domains/%domain%.bytes bytes;
            expires        max;
            try_files      $uri @fallback;
        }
    }

    location /error/ {
        alias   %home%/%user%/web/%domain%/document_errors/;
    }

    location @fallback {
        proxy_pass      http://localhost:8181;
    }

    location ~ /\\.ht    {return 404;}
    location ~ /\\.svn/  {return 404;}
    location ~ /\\.git/  {return 404;}
    location ~ /\\.hg/   {return 404;}
    location ~ /\\.bzr/  {return 404;}

    include %home%/%user%/conf/web/*nginx.%domain_idn%.conf_letsencrypt;
    include %home%/%user%/conf/web/s%proxy_system%.%domain%.conf*;
}
                                        `}</pre>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/nginx-template.jpg" alt="Nginx Template"/>
                                        </div>
                                        <p className="mt--40">Now that you have installed the templates you will need to go into your VestaCP control panel and change the sub-domain you want the OnlyOffice Document server running on to use the new template that you have installed.</p>
                                        <h5>Now you know how to install OnlyOffice Document Server with VestaCP. Everything should be running smoothly now. If you have any issues do leave a comment below and I will try my best to help you out. If you succeed do let me know as well.</h5>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default InstallOnlyOfficeDocumentServerWithVestaCP;