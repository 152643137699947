import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class WhyYouNeedAPersonalWebsite extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Why you need a personal website?']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Why you need a personal website?' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Why you need a personal <br /> website?</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Aug 17, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h2>The importance of a Personal Website</h2>
                                        <p><b>I bet you’re thinking, what is Zed going on about now? I mean you’re not a celebrity, you are not a company, you are just a normal person. So why would you need a personal website? Because a website gives you a competitive edge to your personal brand. It allows you to choose how you are going to be perceived. To display your skills in a dynamic way that gives you the creative exposure that you lack. It helps people including recruiters to find avenues to communicate with you in a centralized and updated manner.</b></p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-03.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">In 2020, almost everything that is worth anything has a website. Websites lend credibility towards the intended goals it serves to achieve. When was the last time you saw a brand without a website and felt confident in what it had to offer? If I had to guess I would say a few decades ago. Let’s dive deeper into the importance of a personal website and the benefits it provides you. See for yourself what you will be missing out on if you do not have your own personal website.</p>
                                        <h3>Self-Branding</h3>
                                        <p>When it comes to branding, you ought to handle it yourself. You should be in control of what your brand says about you. In the age of high-speed communication and the information superhighway, people are quick to look you up. Did you hear about the man that didn’t get his dream job because of a silly social media post? That’s right, he failed to control his brand thus losing out on his dream job. I know you are smarter than that, you don’t have anything incriminating on your social media. But take it one step further, with a personal website.</p>
                                        <h4>Personal Brand</h4>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/Clickthrough-rates-brand-non-brand-Google.png" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">Your personal brand should be ready and available as the first entry when someone searches for you online. According to Smart Insights, the first result in a search gets 80% of all clicks. When you are Googled, and you will be, you need to lead people towards the direction you want them to go. Your website. There you will be able to tailor your bespoke personal brand to display yourself in the best light available. Curate the information you expose your visitors to, build trust with your viewers, and let them awe at your potential. Give them an idea of what you can do for them and why you are the best man for the job.</p>
                                        <h4>Competitive Edge</h4>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/percentage-of-americans-over-25-with-4-years-of-college-1940-2019.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">Today there is a ridiculous amount of competition and I am not even talking about online. Just offline think about how many people graduate with bachelor’s degrees in 2019 when compared to when the millennium just started. EducationData.org states on their blog post about College Graduation Statistics, that the percentage of Americans over 25 that graduated with more than 4 years of college has risen from 25 percent in the year 2000 to 36% in 2019. You need to stay competitive, you need every edge you can get to differentiate yourself.</p>
                                        <p>By having a personal website you can stand out from the 3.9 million students who graduated in 2019, and those numbers are just from the United States alone. I believe that by 2030 everyone will have a personal website, just like everyone has social media accounts today. So it is really important that you get started now, give yourself a head start in the competition of life. Be a winner.</p>
                                        <h4>Choose how you are perceived</h4>
                                        <p>If you were to Google your name right now, what would show up? Go ahead give it a go, I will wait. If you are lucky your social media accounts are displayed, hopefully, your LinkedIn profile is there among the mix. But that’s the thing do you really want your private social media accounts to be the first thing people see about yourself. You deserve privacy, your personal life shouldn’t dictate your personal brand. That’s where you come in and choose how you are perceived by making sure the first result in the search engines is your website. Your website can be tailored to how you want it to look, be it professional or just a casual place where information about you is neatly arranged.</p>
                                        <h3>Portfolio</h3>
                                        <p>You work hard, you are capable of things, and you are experienced. You earned your place in the pecking order, and you have something to show for it. Long gone are the days of typing out your resume in Microsoft Word awkwardly and listing your skills and expertise. Displaying where you worked previously and your educational background. Then proceeding to cross your fingers, pray to God almighty that he blesses your CV before you send it out hoping for the best. Don’t wait to be interviewed, let the interviewer come to you. It’s like being preapproved for a credit card, it feels good, doesn’t it.</p>
                                        <h4>Display your work</h4>
                                        <p>Why buy into the waiting game, display your work in all its glory for everyone to see. Reconquer your name in cyberspace. Do you have a common name? Well, you take it back with your own personal website. People who did not even intend to find you, people who may be looking for someone with a similar name as yourself now will find you instead. This is where your ostentatious display of the work you have done in your career will blow their hats off. This is called self-marketing, dominating your namespace, and being proactive.</p>
                                        <h4>Dynamic Resume</h4>
                                        <p>Often times you will find that people have not updated their resume in years. Most of us create our resumes for one single purpose, to get a job. Once we have that job, our respective resumes start collecting dust and are left until the unfortunate day that it is needed once again. Your social media pages are not sufficient as a dynamic and up to date source of information about the professional steps your career is taking. You need something that is an amalgamation of multiple sources of curated information that is kept up to date. You need a future proof resume, one that is dynamic and awesome. Somewhere that visitors can come to and immediately be mesmerized. That’s how you get headhunted, that is how you move up in the world.</p>
                                        <h4>Creative Exposure</h4>
                                        <p>People are busy, we lead busy lives, we don’t have time to conduct a detailed research about particular persons or topics. What we usually do is go online and try to find an authoritative source of information that can give us what we are looking for. Business opportunities are like that, which is why you must get creative with your personal brand exposure. Think of your website as a gatekeeper that leads and steers people seamlessly towards synergy and cooperative business opportunities. Not just that but also as your number one cheerleader, your number one fan, always on your side, and always looking to open the doors of opportunity for you and only you.</p>
                                        <h3>Synergistic Communication</h3>
                                        <p>They say communication is key, but what they don’t tell you is that communication is the key to opportunities. There are tons of jobs out there, you are absolutely qualified, but the one problem is you’re not famous. Next problem, you don’t know the right people. There is a gap here that needs to be bridged for you to achieve the goals you set. You need to streamline the ways and methods that are available to contact you. What would be the point of all your hard work if no one could find your contact information?</p>
                                        <h4>Contact Information</h4>
                                        <p>Your website is the perfect place to facilitate synergistic communication. What’s that you ask? It’s just fancy marketing talk for one singular place and point of contact to list all the forms of communication by which you can be reached. Different people prefer different ways of communication. Some would rather call you, some might prefer emailing you, and others may want to reach you via social media. In this day and age, you can’t afford to exclude anyone, which is why consolidating the means to contact you in so important. Make the people who would like to contact you comfortable even before they have the chance to speak to you.</p>
                                        <h4>Helps recruiters find you</h4>
                                        <p>A personal website offers, much more than just a way to build your personal brand. It gives you a place that has the ability to attract recruiters and head hunters. Your website will help recruiters find you. Let’s face it, you have expended plenty of time and effort building your career and personal brand to the point where you are getting headhunted. You are awesome, so help these recruiters find you by having your own personal website. Give them the confidence to reach out.</p>
                                        <h4>Social Media</h4>
                                        <p>Unless you have a really unique name, chances are there will be more than one person on social media platforms that share your name. Don’t forget your haters, people who are jealous of you can register social media accounts under your name just to tarnish your reputation. By having links to your social media accounts on your website you can avoid this. There won’t be any confusion as visitors on your website can be sent to your official social media pages. They will not have to go through the trouble to distinguish your real profiles, from malicious ones, or rather other people who share your name.</p>
                                        <h2>Why you need a personal website?</h2>
                                        <p>So above are some of the important reasons to answer the question of why you need a personal website. To summarize a personal website, allows you to control your brand, dynamically display your work and resume, and most importantly help people find you and facilitate synergistic communication. It gives you a much needed competitive edge to make the most of any situation you may face. There just is no downside to having your own personal website. Only Upsides.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default WhyYouNeedAPersonalWebsite;