import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userId: null,
    keyword: 'User does not have a keyword.',
    campaign: 'User did not come through an ad campaign.',
    promoTimestamp: parseInt((document.cookie.split(';').filter(cookie => (cookie.split('=')[0])?.includes('promoEndDate')))[0]?.split('=')[1])
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setKeyword(state, action){
            state.keyword = action.payload
        },
        setCampaign(state, action){
            state.campaign = action.payload
        },
        setPromoTimestamp(state, action){
            state.promoTimestamp = action.payload
        },
    }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
