import React from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {FiCheck} from "react-icons/fi";
// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

// class HopeSpring extends Component{
//     constructor () {
//         super()
//         this.state = {
//           isOpen: false
//         }
//         this.openModal = this.openModal.bind(this)
//     }
//     openModal () {
//         this.setState({isOpen: true})
//     }
//     render(){
//
//     }
// }

const LGMSoftwareDeployment = () => {
    // const [isOpen, setIsOpen]  = useState(false)
    // const openModal = () => {
    //     setIsOpen(true)
    // }
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', 'LGM ArcGIS Upgrade']);
    _paq.push(['trackPageView']);
    return (<React.Fragment>
            <PageHelmet pageTitle='LGM ArcGIS Enterprise'/>

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--12"
                 data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">LGM Software Upgrade</h2>
                                <p>ArcGIS Enterprise</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}
            <div className="rn-portfolio-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="row row--35 mb--50">
                                    <div className="col-lg-6">
                                        <div className="inner">
                                            <div className="section-title">
                                                <h3 className="title">ArcGIS Enterprise</h3>
                                                <p className="description mt--30">Lembaga Getah Malaysia is continuously updating and upgrading their systems
                                                to better serve the country. As part of this they have deployed industry leading GIS Mapping and Analytics software.</p>
                                                <p className="description">ArcGIS Enterprise is a software system for GIS, powering analytics, visualization, mapping and data management. It is the main infrastructure of the esri suite of applications.</p>
                                            </div>
                                            <div className="portfolio-view-list d-flex flex-wrap">
                                                <div className="port-view">
                                                    <span>Industry</span>
                                                    <h4>Government Agency</h4>
                                                </div>

                                                <div className="port-view">
                                                    <span>Project Type</span>
                                                    <h4>Software Upgrade</h4>
                                                </div>

                                                <div className="port-view">
                                                    <span>Platform</span>
                                                    <h4>ArcGIS Enterprise</h4>
                                                </div>
                                            </div>
                                            {/*<div className="portfolio-details-btn mt--30">*/}
                                            {/*    <a className="btn-default btn-border"*/}
                                            {/*       href="https://hopespringfoundation.com">Launch The Site</a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h4 className="title">Where we came in</h4>
                                                    <p className="description mt--30">We were contacted by our client to facilitate in the license renewal and software upgrade of ArcGIS Enterprise. We consulted with them regarding best way to upgrade their deployment with minimal downtime.</p>
                                                    <p className="description mt--30">All in all we provided the
                                                        following services:</p>
                                                    <ul className="list-style--1">
                                                        <li><FiCheck/> ArcGIS Enterprise Upgrade</li>
                                                        <li><FiCheck/> License Renewal</li>
                                                        <li><FiCheck/> Backup Solutions</li>
                                                        <li><FiCheck/> Consultation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-thumb-inner">
                                    <div className="thumb mb--30">
                                        <img src="/assets/images/landing/arcgis-01.png" alt="argis enterprise"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* Start Related Work */}
            <div className="portfolio-related-work pb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">Related Work</span>
                                <h2 className="title">Other Projects</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--10">
                        {/* Start Single Portfolio */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb">
                                    <a href="/suk-kedah-software-deployment">
                                        <img src="/assets/images/portfolio/related/arcgis-portfolio-suk-kedah-related.jpg"
                                             alt="Portfolio-images"/>
                                    </a>
                                </div>
                                <div className="inner">
                                    <h4><a href="/suk-kedah-software-deployment">SUK Kedah Software Deployment</a></h4>
                                    <span className="category">ArcGIS Enterprise</span>
                                </div>
                            </div>
                        </div>
                        {/* End Single Portfolio */}
                        {/* Start Single Portfolio */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb">
                                    <a href="/pdrm-software-deployment">
                                        <img src="/assets/images/portfolio/related/arcgis-portfolio-pdrm-related.jpg"
                                             alt="Portfolio-images"/>
                                    </a>
                                </div>
                                <div className="inner">
                                    <h4><a href="/pdrm-software-deployment">PDRM Software Deployment</a></h4>
                                    <span className="category">ArcGIS Enterprise</span>
                                </div>
                            </div>
                        </div>
                        {/* End Single Portfolio */}
                    </div>
                </div>
            </div>
            {/* End Related Work */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer/>


        </React.Fragment>)
}

export default LGMSoftwareDeployment;
