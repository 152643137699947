// React Required
import React, {Component, useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';

// Create Import File
import './index.scss';

// Dark Home Layout
import Home from './Main/Home';

// Pages Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import Blog from "./elements/Blog";
import error404 from "./elements/error404";
import Portfolio from './elements/portfolio/Portfolio';


// Blog Items
import DifferentWaysOfGeneratingWebsiteTraffic from "./blog/DifferentWaysOfGeneratingWebsiteTraffic";
import WhyWebsitesAreTheNewBusinessCards from "./blog/WhyWebsitesAreTheNewBusinessCards";
import WhyYouNeedAPersonalWebsite from './blog/WhyYouNeedAPersonalWebsite';
import InstallOnlyOfficeDocumentServerWithVestaCP from './blog/InstallOnlyOfficeDocumentServerWithVestaCP';
import Top5EssentialPagesInAnyWebsite from './blog/Top5EssentialPagesInAnyWebsite'
import ActiveVsPassiveWebsites from './blog/ActiveVsPassiveWebsites'
import Top10MustHaveWordPressPlugins from './blog/Top10MustHaveWordPressPlugins'
import HTTPStatusCodes from './blog/HTTPStatusCodes'
import DifferentTypesofWebDevelopment from './blog/DifferentTypesofWebDevelopment'
import AwesomeSearchEngineOptimizationTools from './blog/AwesomeSearchEngineOptimizationTools'
import GoogleRankingFactorsThatYouNeedToKnow from './blog/GoogleRankingFactorsThatYouNeedToKnow'
import WhyIsSearchEngineOptimizationImportant from './blog/WhyIsSearchEngineOptimizationImportant'
import WhatTypeOfWebsiteYourCompanyNeeds from './blog/WhatTypeOfWebsiteYourCompanyNeeds'
import DifferentTypesOfWebsites from './blog/DifferentTypesOfWebsites'

// Portfolio Items
import MuhajirClinic from './portfolio/MuhajirClinic'
import FutureCloud from './portfolio/FutureCloud'
import AiChatbot from './portfolio/AiChatbot';
import TechZ from './portfolio/TechZ';
import LittleSteps from './portfolio/LittleSteps';
import HopeSpring from './portfolio/HopeSpring';
import EnglishQuill from './portfolio/EnglishQuill';
import Todo from './portfolio/Todo';
import FHMalaysia from './portfolio/FHMalaysia';
import ThinkAt from './portfolio/ThinkAt';
import Cushionflute from './portfolio/Cushionflute';

import {BrowserRouter, Switch, Route, withRouter, useLocation, Redirect} from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'
import BELifestyles from "./portfolio/BELifestyles";
import Loobamind from "./portfolio/Loobamind";
import Verdas from "./portfolio/Verdas";
import SweetQuotes from "./portfolio/SweetQuotes";
import Zdict from "./portfolio/zdict";
import WebsiteDevelopment from "./LandingPages/WebsiteDevelopment";
import store from "./store";
import {Provider} from "react-redux";
import WebAppDevelopment from "./LandingPages/WebAppDevelopment";
import MobileAppDevelopment from "./LandingPages/MobileAppDevelopment";
import ArcGISEnterpriseDeployment from "./LandingPages/ArcGISEnterpriseDeployment";
import ServerDeployment from "./LandingPages/ServerDeployment";
import ScriptAutomation from "./LandingPages/ScriptAutomation";
import PDRMSoftwareDeployment from "./portfolio/PDRMSoftwareDeployment";
import SUKKEDAHSoftwareDeployment from "./portfolio/SUKKEDAHSoftwareDeployment";
import LGMSoftwareDeployment from "./portfolio/LGMSoftwareDeployment";
import TroubleshootingArcGISEnterprise from "./blog/TroubleshootingArcGISEnterprise";

// import ScrollToTop from 'react-scroll-up';

const tagManagerArgs = {
    gtmId: 'GTM-MTRGRRJ'
}
TagManager.initialize(tagManagerArgs)

function _ScrollToTopPage(props) {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

function useGaTracker(props) {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize("UA-170606302-1");
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
    return props.children
};

const ScrollToTopPage = withRouter(_ScrollToTopPage)
const GaTracker = withRouter(useGaTracker)

class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <GaTracker>
                        <ScrollToTopPage>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>

                                {/* Pages Layot */}
                                <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>

                                {/* Blog Items */}
                                <Route exact
                                       path={`${process.env.PUBLIC_URL}/different-ways-of-generating-website-traffic`}
                                       component={DifferentWaysOfGeneratingWebsiteTraffic}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/why-websites-are-the-new-business-cards`}
                                       component={WhyWebsitesAreTheNewBusinessCards}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/why-you-need-a-personal-website`}
                                       component={WhyYouNeedAPersonalWebsite}/>
                                <Route exact
                                       path={`${process.env.PUBLIC_URL}/install-onlyoffice-document-server-with-vestacp`}
                                       component={InstallOnlyOfficeDocumentServerWithVestaCP}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/top-5-essential-pages-in-any-website`}
                                       component={Top5EssentialPagesInAnyWebsite}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/active-vs-passive-websites`}
                                       component={ActiveVsPassiveWebsites}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/top-10-must-have-wordpress-plugins`}
                                       component={Top10MustHaveWordPressPlugins}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/http-status-codes`}
                                       component={HTTPStatusCodes}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/different-types-of-web-development`}
                                       component={DifferentTypesofWebDevelopment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/awesome-search-engine-optimization-tools`}
                                       component={AwesomeSearchEngineOptimizationTools}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/google-ranking-factors`}
                                       component={GoogleRankingFactorsThatYouNeedToKnow}/>
                                <Route exact
                                       path={`${process.env.PUBLIC_URL}/why-is-search-engine-optimization-important`}
                                       component={WhyIsSearchEngineOptimizationImportant}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/what-type-of-website-your-company-needs`}
                                       component={WhatTypeOfWebsiteYourCompanyNeeds}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/different-types-of-websites`}
                                       component={DifferentTypesOfWebsites}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/troubleshooting-arcgis-enterprise-server-deployment`}
                                       component={TroubleshootingArcGISEnterprise}/>

                                {/* Portfolio Items */}
                                <Route exact path={`${process.env.PUBLIC_URL}/muhajirclinic`}
                                       component={MuhajirClinic}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/futurecloud`} component={FutureCloud}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/aichatbot`} component={AiChatbot}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/techz`} component={TechZ}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/littlesteps`} component={LittleSteps}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/hopespring`} component={HopeSpring}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/englishquill`} component={EnglishQuill}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/todoapp`} component={Todo}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/fhmalaysia`} component={FHMalaysia}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/thinkat`} component={ThinkAt}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/cushionflute`} component={Cushionflute}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/BELifestyles`} component={BELifestyles}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/loobamind`} component={Loobamind}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/verdas`} component={Verdas}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/sweetquotes`} component={SweetQuotes}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/zdict`} component={Zdict}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/pdrm-software-deployment`} component={PDRMSoftwareDeployment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/lgm-software-deployment`} component={LGMSoftwareDeployment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/suk-kedah-software-deployment`} component={SUKKEDAHSoftwareDeployment}/>

                                {/* Landing Pages */}
                                <Route exact path={`${process.env.PUBLIC_URL}/website-development`} component={WebsiteDevelopment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/webapp-development`} component={WebAppDevelopment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/mobileapp-development`} component={MobileAppDevelopment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/arcgis-enterprise-deployment`} component={ArcGISEnterpriseDeployment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/server-deployment`} component={ServerDeployment}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/script-based-automation`} component={ScriptAutomation}/>

                                {/* Redirections */}
                                {/* Index Pages */}
                                <Redirect exact from="/about-web-design" to="/about"/>
                                <Redirect exact from="/blog-web-design" to="/blog"/>
                                <Redirect exact from="/contact-web-design" to="/contact"/>
                                {/* <Redirect exact from="/web-design" to="/web-development" /> */}
                                <Redirect exact from="/web-design" to="/about"/>
                                <Redirect exact from="/aipro" to="/aichatbot"/>

                                {/* Blog Pages */}
                                <Redirect excat from="/seo/different-ways-of-generating-website-traffic"
                                          to="/different-ways-of-generating-website-traffic"/>
                                <Redirect exact from="/web-design/why-websites-are-the-new-business-cards"
                                          to="/why-websites-are-the-new-business-cards"/>
                                <Redirect exact from="/web-design/why-you-need-a-personal-website"
                                          to="/why-you-need-a-personal-website"/>
                                <Redirect exact from="/linux/install-onlyoffice-document-server-with-vestacp"
                                          to="/install-onlyoffice-document-server-with-vestacp"/>
                                <Redirect exact from="/web-design/top-5-essential-pages-in-any-website"
                                          to="/top-5-essential-pages-in-any-website"/>
                                <Redirect exact from="/web-design/active-vs-passive-websites"
                                          to="/active-vs-passive-websites"/>
                                <Redirect exact from="/web-development/top-10-must-have-wordpress-plugins"
                                          to="/top-10-must-have-wordpress-plugins"/>
                                <Redirect exact from="/web-development/http-status-codes" to="/http-status-codes"/>
                                <Redirect exact from="/seo/awesome-search-engine-optimization-tools"
                                          to="/awesome-search-engine-optimization-tools"/>
                                <Redirect exact from="/seo/google-ranking-factors" to="/google-ranking-factors"/>
                                <Redirect exact from="/seo/why-is-search-engine-optimization-important"
                                          to="/why-is-search-engine-optimization-important"/>
                                <Redirect exact from="/web-design/what-type-of-website-your-company-needs"
                                          to="/what-type-of-website-your-company-needs"/>
                                <Redirect exact from="/web-design/different-types-of-websites"
                                          to="/different-types-of-websites"/>

                                {/* Porfolio Pages */}
                                <Redirect exact from="/topic/muhajirclinic" to="/muhajirclinic"/>
                                <Redirect exact from="/topic/futurecloud" to="/futurecloud"/>
                                <Redirect exact from="/topic/aipro" to="/aichatbot"/>
                                <Redirect exact from="/topic/techz" to="/techz"/>
                                <Redirect exact from="/topic/littlesteps" to="/littlesteps"/>
                                <Redirect exact from="/topic/hopespringfoundation" to="/hopespring"/>
                                <Redirect exact from="/topic/englishquill" to="/englishquill"/>
                                <Redirect exact from="/topic/todoapp" to="/todoapp"/>

                                {/* Dead Links */}
                                <Redirect from="/my-product_tags/*" to='/'/>
                                <Redirect from="/topic/*" to='/portfolio'/>
                                <Redirect from="/category/*" to='/blog'/>
                                <Redirect from="/topic-category/*" to='/portfolio'/>

                                {/* Mail */}
                                <Route path='/mail' component={() => {
                                    window.location.href = 'https://webmail.zedpro.me';
                                    return null;
                                }}/>

                                {/* 404 Error */}
                                <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                                <Route component={error404}/>

                            </Switch>
                        </ScrollToTopPage>
                    </GaTracker>
                </BrowserRouter>
            </Provider>
        )
    }
}

// serviceWorker.register();
createRoot(document.getElementById('root')).render(<Root/>);