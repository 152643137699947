import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiCode, FiHelpCircle, FiMonitor, FiServer} from "react-icons/fi";
import {useLocation} from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from "../LandingPages/components/Footer";


import Helmet from "../component/common/Helmet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Hero from "./components/Hero";
import Bullet from "./components/Bullet";
import Platform from "./components/Platform";
import {useDispatch} from "react-redux";
import {userActions} from "../store/user";
import Brand from "../elements/Brand";


const capitalize = string => {
    if(string){
        const stringList = string.split(" ");
        const finalString = stringList.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return finalString
    } else {
        return undefined
    }
}


const ServerDeployment = () => {
    const [promoActive, setPromoActive] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const query = useQuery();
    const keyword = capitalize(query.get('keyword'));
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', keyword ? keyword : 'Server Deployment Services']);
    _paq.push(['trackPageView']);

    // Remove third party tracking params
    useEffect(() => {
        window.history.replaceState(null, `${keyword ? 'Server Deployment Services' : keyword} | Deployment & Maintenance | Kuala Lumpur`, keyword ? `/server-deployment${keyword&&`?keyword=${keyword}`}` : '/server-deployment')
    }, [keyword]);

    useEffect(() => {
        if(keyword){
            dispatch(userActions.setKeyword(keyword));
        }
    }, [keyword, dispatch])
    useEffect(() => {
        dispatch(userActions.setCampaign('Server Leads'));
    }, [dispatch])

    const ServiceList = [
        {
            icon: <FiServer/>,
            title: 'Deployment',
            description: 'On premise effective and efficient deployment around Malaysia. Secure and trusted.'
        },
        {
            icon: <FiMonitor/>,
            title: 'Maintenance',
            description: 'All systems require levels of maintenance, ZedPro provides routine security & feature updates.'
        },
        {
            icon: <FiCode/>,
            title: 'Development',
            description: 'Custom hardware configurations as well as software services such as web hosting are available.'
        },
        {
            icon: <FiHelpCircle/>,
            title: 'Dedicated Support',
            description: 'While things rarely go wrong, they always can, our team is ready to assist if they ever do.'
        },
    ]
    return (
        <div className="active-dark">
            <Helmet pageTitle={keyword ? keyword : 'Server Deployment Services'}/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"
                    isLandingPage={keyword}/>

            {/* Start Hero Area */}
            <Hero
                page="serverdeployment"
                promoActive={promoActive}
                setPromoActive={setPromoActive}
                keyword={keyword}
                title='Server Deployment & Maintenance Services'
                price={3000}
                description='Take your business to the next level, retain control of you data and hosted services, we have you covered for server installations, configuration, and maintenance.'
                hook='High Availability Deployments are available.'
            />
            {/* End Hero Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--1" style={{
                padding: '100px 50px'
            }}>
                {/*<Trusted/>*/}
                <div className="section-title" style={{
                    display: "flex",
                    justifyContent: 'center',
                    marginBottom: 50
                }}>
                    <span className="subtitle subtitleMod--medium">DEPLOYED AT</span>
                </div>
                <Brand brandStyle="branstyle--2" brands={[
                    // {image:"/assets/images/brand/brand-01.png",altText:"PDRM"},
                    {image:"/assets/images/brand/brand-02.png",altText:"SUK Kedah"},
                    // {image:"/assets/images/brand/brand-03.png",altText:"Lembaga Getah Malaysia"},
                    // {image:"/assets/images/brand/brand-04.png",altText:"U Mobile"},
                    {image:"/assets/images/brand/brand-05.png",altText:"Geoinfo Services Sdn Bhd"},
                    {image:"/assets/images/brand/brand-06.png",altText:"Handal"},
                ]}/>
            </div>
            {/* End Service Area  */}

            {/*        /!* Start Service Area *!/*/}
            <div className="service-wrapper service-white" style={{
                padding: '100px 50px'
            }}>
                <Services
                    serviceList={ServiceList}
                    title='One Stop Solution'
                />
            </div>
            {/*        /!* End Service Area *!/*/}
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Bullet
                    image='/assets/images/landing/server-room.jpg'
                    title='Why deploy your own server?'
                    subTitle='Running your own server is essential for any business as it allows you to:'
                    list={['Control your data', 'Meet performance needs', 'Custom configurations', 'Niche Software']}
                />
            </div>
            <div
                className="align-items-center bg_color--5"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Platform
                    image='/assets/images/landing/aws.jpg'
                    title='What are the available options?'
                    description='Servers can be deployed on premise, colocated within datacenters or as virtual private servers withing major cloud providers such as Google, AWS, and Azure. Servers can be configured to run custom or traditional software such as, cPanel, Mail, and Database services.'
                    ctaTitle='More about AWS'
                    ctaLink='https://aws.amazon.com'
                />
            </div>
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
                id='contact'
            >
                <Contact
                    title='Schedule a free consultation'
                    description={
                        <span>
                        Jump on a quick zoom call with us, and we will discuss your requirements and propose a few solutions.
                        Upon confirmation we will start deploying your hardware & software based upon the pre-agreed scope and specifications.
                        <br/> <br/>
                        Then within the agreed upon timeframe we will prepare for handover and training for your staff.
                        Lastly a quick session to summarise the deployment and answer any questions you or your company may have.
                    </span>
                    }
                    phoneTrigger='AW-620745259/nRKICKL49vACEKuk_6cC'
                    emailTrigger='AW-620745259/o4mRCNeVzPACEKuk_6cC'
                    formTrigger='AW-620745259/IyNECPuP5OUCEKuk_6cC'
                    isLandingPage={keyword}
                />
            </div>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer
                ctaTitle={`From A to Z${promoActive ? ' from only RM20,000' : ''}`}
            />

        </div>
    )
}
export default ServerDeployment;
