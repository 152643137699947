import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class Top10MustHaveWordPressPlugins extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Top 10 Must-have WordPress Plugins']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Top 10 Must-have WordPress Plugins' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Top 10 Must-have WordPress Plugins</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 29, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>When constructing a WordPress site I always ponder as to what plugins must I install. What is essential and what plugins do I simply not need. While there are plenty of Must-have plugins on the store, some are not as important as others. Below is a list of the top 10 must-have WordPress plugins that I always am sure to use.</p>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/stephen-phillips-hostreviews-co-uk-sSPzmL7fpWc-unsplash-1-2.jpg" alt="Wordpress"/>
                                            </div>
                                        <p><strong>These WordPress plugins are my favorite and I do recommend you check them out. Each item is a plugin I personally have been using for years. They make my life much easier as a web developer.</strong></p>
                                        <p>However, they are not the only plugins available within their respective categories. If these specific plugins don't have the features you are looking for don't worry. The official WordPress plugin store is sure to have one that meets your exact requirements.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/akismet/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Akismet Anti-Spam</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/akismet-anti-spam.jpg" alt="Aksimet"/>
                                            </div>
                                        <p>This plugin comes default with WordPress but still earns it stop as one of the top 10 must-have WordPress plugins. People often leave it deactivated or fail to setup it up correctly. The setup is very simple, you simply go to their website and sign up. Then you are given an API key that you enter into your website to complete the activation. As the name suggests this plugin scans comments on your site's posts. </p>
                                        <p>Since it is also running on literally hundreds of millions of sites, it goes through billions of comments. With the sheer volume is scans it is able to get a general idea of what is spam and what is not. There is nothing worse than malicious links being posted on your site. You would not want your visitors to accidentally click on them now, would you? To date, Akismet has blocked more than 500 billion spam comments.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/all-in-one-wp-migration/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">All-in-One WP Migration</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/all-in-one.jpg" alt="All in One"/>
                                            </div>
                                        <p>Things often go wrong, and as everyone will tell you there is no excuse for not having a backup. The value of a backup cannot be understated. This plugin serves two purposes; it backs things up, and it lets you upload that backup to any site. With the All-in-One WP Migration plugin, you can have your entire site safe within a single file. You can store that file and when something goes wrong, you’re covered. </p>
                                        <p>The same file can also be used if you would like to migrate your website to a different domain. Made by <a aria-label="undefined (opens in a new tab)" href="https://servmask.com/" target="_blank" rel="noreferrer noopener">ServMask</a> this plugin is available on the WordPress store for free. As an added bonus you can choose to save your file not only to the desktop but many different cloud storage providers.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/colorlib-login-customizer/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Colorlib Login Customizer</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/login-customizer.jpg" alt="Colourlib Login Customizer"/>
                                            </div>
                                        <p>The traditional WordPress login page is bland and boring, it looks like every other WordPress site. Usually, a theme will not include the option to customize your site's login page. Thankfully <a href="https://colorlib.com/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Colorlib</a> has provided you with a free plugin that allows easy customization of your login page. There are many potential reasons you might want to beautify your login page. </p>
                                        <p>You may have clients or subscribers who sign up and need to login to view and access content, or post comments. With this plugin, you can change not only the layout but also the styling of the page. You can alter the shape, size, and color of the buttons and add a suitable background. Certainly, a must-have for your WordPress site.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/loginizer/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Loginizer</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/loginizer.jpg" alt="Loginizer"/>
                                            </div>
                                        <p>The internet is full of spiders that crawl websites constantly, and not all areas welcome as Google’s web crawlers. Many are malicious and when they discover a site, the get to work trying to guess your login credentials. Now while there really isn't much of anything you can do to stop them, you can make it harder. Why allow them to continuously attempt to try different login combinations? This plugin helps secure your website by limiting the number of failed logins. </p>
                                        <p>After a certain amount of failed logins, it will block that particular IP address for a certain amount of time. If the offending IP address continues, it will get blocked for 24 hours. This is sure to help increase the security of your site. Not only that but it will also reduce the load on your hosting server. As your site won't have to constantly reject incorrect login credentials. This plugin is made available for free by <a href="https://www.loginizer.com/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">Softaculous</a>.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/redirection/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Redirection</a></strong></em></h3>
                                        <p>Sometimes the URL of your page gets changed. You might want to optimize your URL structure for better SEO. You might want to change it to make it shorter perhaps, or maybe just to have specific keywords in your URL. However, more than likely your site URLs have been index by search engines. These might not change right away. Not only that but other websites might have links to your site's pages, these links are important. Backlinks are vital for your page's search engine rankings. When your URL changes, if anyone were to click on the outdated links they will get the famous 404 error. Page not found. </p>
                                        <p>Not only is this very bad for your search engine optimization but it also reduced your organic traffic you would have received to your site. Visitors who get a 404 error are more than likely to go to a different website. You don’t want that. I know I don’t. With this plugin, you can redirect old URLs to their respective new ones. There are also a ton of other conditional redirects that makes this plugin an absolute must-have. This plugin is made available for free on the official WordPress store by <a href="https://redirection.me/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">John Godley</a>.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/w3-total-cache/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">W3 Total Cache</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/w3-total-cache.jpg" alt="W3 Total Cache"/>
                                            </div>
                                        <p>One of the major factors that lead to a high bounce rate for websites is high load times. WordPress is an amazing platform for websites. However, as a rule, it is generally slow. To reduce load times and to improve SEO you must speed your website up. An easy way to do so would be to install a caching plugin. This ensures that your server doesn't have to keep rendering the same page every single time. What the plugin also does is combine and minify your CSS and JavaScript assets. </p>
                                        <p>This is done so that not as many requests have to be made and the total size of your website's assets are also reduced. Another thing it does is compress your entire site before it is sent to the user. Then your website is deflated where it is displayed seamlessly in the browser. This plugin is made available for free on the official WordPress store by <a aria-label="undefined (opens in a new tab)" href="https://www.boldgrid.com/" target="_blank" rel="noreferrer noopener">BoldGrid</a>. W3 Total Cache has definitely earned its spot in the top 10 must-have WordPress plugins list.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/wps-hide-login/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">WPS Hide Login</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/hide-login.jpg" alt="WPS Hide Login"/>
                                            </div>
                                        <p>As we have spoken about malicious attackers trying to brute force their way into your site via the login page. It is important to note that the page wp-login.php is the most commonly guessed and attacked page. It also informs anyone that you are using WordPress, and we don’t want to aid them in any way. Furthermore, the URL slug is not the nicest. Fear not, we can change it with this plugin.</p>
                                        <p>It allows you to change your login URL into something neater. It also hides the wp-login.php and wp-admin.php pages and makes them inaccessible to anyone who is not logged in. So essentially you end up with a more secure site with a single custom login page. This plugin is made available for free on the official WordPress store by <a href="https://wpserveur.net/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">WPServeur</a>.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/duplicate-post/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Yoast Duplicate Post</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/yoast-duplicate-post.jpg" alt="Yoast Duplicate Post"/>
                                            </div>
                                        <p>Creating pages from scratch and configuring its layout, tags, categories can be cumbersome and tedious. When you have a page set up just right, you should be able to duplicate your hard work at a click of a button. That’s where this plugin comes in. It allows you to clone posts and any other type of pages with a simple click. You can even create a new draft from an existing page. </p>
                                        <p>Once you have duplicated the page you need, you may start editing your page and focus on the important things. This way with half of your work already done, not only do you save plenty of time and effort but you also are able to standardize the layout of certain pages. This plugin is made available for free on the official WordPress store and is brought to you by Yoast.</p>
                                        <h3><em><strong><a href="https://wordpress.org/plugins/wordpress-seo/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Yoast SEO</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/yoast-seo.jpg" alt="Yoast SEO"/>
                                            </div>
                                        <p>On-page search engine optimization is a very important part of your website. Without proper optimization, your website will most likely not rank on search engines. This has the potential to servery impact the traffic your website will receive. This is why having this plugin is going to make your life so much easier. </p>
                                        <p>Yoast SEO allows you to set custom meta titles and meta descriptions. It gives you guidelines and suggestions on how to improve not only your SEO but also the readability of your content. It also generates XML Sitemaps and has a good schema implementation that helps search engines make sense of your website. </p>
                                        <p>An all-round excellent plugin that will help you rank up in the search results. This plugin is made available for free on the official WordPress store and is brought to you by <a aria-label="undefined (opens in a new tab)" href="https://yoast.com/" target="_blank" rel="noreferrer noopener">Yoast</a>. Hands down it is the best among the top 10 must-have WordPress plugins.</p>
                                        <h3><em><strong>Analytics Plugins</strong></em></h3>
                                        <p>Integrating an analytics plugin is important for several reasons. You should track your page views to get an idea of the demographic of your visitors. This will help you create better content. Tracking visitors also allows you to fine-tune your advertising by targeting people who have already visited your website. This helps increase conversion. </p>
                                        <p>You should also track the conversion rate and bounce rate within your site. Another reason to have analytics for your site is to discover which of your marketing and promotional campaigns are generating the most traffic. This will help you optimize and streamline your budget and how much you spend on each campaign. </p>
                                        <p>There are plenty of analytics plugins on the official WordPress store. They help you integrate many different analytic codes into the pages of your website. Some worth considering are plugins that integrate Facebook pixels, Google Analytics, or any custom analytic software you may be using.</p>
                                        <h5><strong>There you have it, the top 10 must-have WordPress plugins for your website. Think there should be another on this list? Comment below which is your favorite WordPress plugin.</strong></h5>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default Top10MustHaveWordPressPlugins;