import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class WhatTypeOfWebsiteYourCompanyNeeds extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'What type of Website your company needs?']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='What type of Website your company needs?' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> What type of Website your company <br/> needs?</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />June 29, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/pankaj-patel-Ylk5n_nd9dA-unsplash" alt="By Patel"/>
                                    </div>
                                    <p>What type of Website your company needs? Although everyone needs a website and an online presence, the type of site you will require differs largely based on the type of business or person you may be. Your target audience also determines what website you will need along with what features you should have.</p>
                                    <h2>Freelancer</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/per-loov-4wOkqiXNP7M-unsplash-e1593945510463.jpg" alt="Freelancer"/>
                                    </div>
                                    <p>If you're a freelancer or a solo act, your budget is probably not on the high end. It would be best to stick with portfolio websites, or CV/Resume sites. For added functionality consider including a blog as well, as that would help greatly when it comes to search engine optimization.</p>
                                    <p>Development platforms best suited for these types of websites are drag and drop cloud-based web development companies. This includes but is not limited to <a href="https://www.wix.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Wix</a>, <a href="https://www.squarespace.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Squarespace</a>, and if you're ready for a challenge, <a href="https://wordpress.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">WordPress</a>.</p>
                                    <p>When choosing a website, you can either build it yourself if you feel confident in your skill, or you can have someone build it for you. Then you can manage it yourself as these systems have very user-friendly back-ends.</p>
                                    <h2>Sole Proprietor</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/launchpresso-IOM28XWsk-g-unsplash.jpg" alt="Sole Proprietor"/>
                                    </div>
                                    <p>If you’re running a business whether it be a physical establishment offering a service or product, or an online-only business. It would benefit your business greatly if you had an online presence. Consider a corporate website. It might have some background about you and a blog to update clients with the latest news from your company. If you like you may even add e-commerce functionality as well.</p>
                                    <p><a href="https://www.optimizely.com/optimization-glossary/content-management-system/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Content management systems</a> would be the way to go, as they have user-friendly back-ends that allow you to modify content and add functionality with plug-ins. Some of the most popular content management systems are <a href="https://wordpress.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">WordPress</a>, <a href="https://www.joomla.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Joomla</a>, <a href="https://www.drupal.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Drupal</a>, although there are many more. In 2020, over 455 million websites globally use WordPress, an increase of 4% since the previous year.</p>
                                    <p>If you plan to go down the content management systems route, I would highly suggest you have a professional design for you. Then you can learn to manage the <a href="https://www.wikiwand.com/en/Front_end_and_back_end" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">back-end</a> and how to update content along the way to save you some cost in the long run. Of course, if you would like to add more features using plug-ins there is no harm in contacting your favorite web developer.</p>
                                    <h2>SME (Small to Medium Enterprise)</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/filiberto-santillan-1HCb2gPk3ik-unsplash.jpg" alt="SME"/>
                                    </div>
                                    <p>Now that we are entering slightly bigger fields it would be best to start looking at more modular and custom-built options. While you may not have the need currently for a website with tons of functionality, it is best to plan for the future. Your business is important and it is growing, you want a website that can be scaled as your business expands. At this stage e-Commerce is a must, a blog is a necessity. You may even want to start to have more complex <a href="https://www.wikiwand.com/en/Front_end_and_back_end" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">front-ends</a> for your clients. A portal for them to log into and manage their relationship with your company.</p>
                                    <p>The type of website this company will need is highly subjective. Custom websites built using <a href="https://www.python.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">python</a> and <a href="https://www.javascript.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">JavaScript</a> frameworks are not uncommon on this side of the web design spectrum. Back-end frameworks like <a href="https://www.djangoproject.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Django</a> and <a href="https://nodejs.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Node.js</a> are what you will hear your web developer throwing around. For your front-end, I would recommend a framework like <a href="https://reactjs.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">React</a>, <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Bootstrap</a>, <a href="https://vuejs.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Vue.js</a>, and <a href="https://jquery.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">jQuery</a>.</p>
                                    <p>These frameworks make your website highly versatile and customizable, not just in the short-run but also in the long-run. You can add any type of features, as your web developer will be coding and programming them from scratch. Your website can expand will your business needs as you head for glory. You will also need a web developer to stay on to manage and service the website over time.</p>
                                    <h2>Large Corporation</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/taylor-vick-aWslrFhs1w4-unsplash.jpg" alt="Large Corporation"/>
                                    </div>
                                    <p>Stability is very important for large corporations, downtime is catastrophic, things must simply work. Your website must be highly customized and optimized. It should also be spread out with multiple servers and <a href="https://www.f5.com/services/resources/glossary/load-balancer" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">load balancers</a>. Across the world to accommodate surges in traffic that you will experience. Remember downtime costs money and loss of income, which makes shareholders very upset.</p>
                                    <p>Apart from custom websites built using custom frameworks, both on the back-end and front-end, you will require a dedicated team. They will monitor and service the many different components that make your website a smooth and user-friendly experience. Using load balancers that redirect traffic from your domain to different nodes. You can ensure that you have redundancy and reduce any possible downtime to a minimum.</p>
                                    <p>The feature set that we are looking at here is unlimited. Whatever you want you will get, you even get features you didn't even know you needed. Such as custom user tracking, analytics, and anything specific to your niche. Naturally, all that functionality comes at a premium, but it also ensures your business stays competitive and on the ball.</p>
                                    <h2>So what type of website does my company need?</h2>
                                    <p>What type of website your company needs depends on what direction you hope to move in and the confidence you have in your company. As well as the long term sustainability of what you hope to accomplish in the future. You will have to decide what website best suits your current needs. As well as any likely needs you may have in the future. Keep in mind you do not want to spend a lot of money today, only to realize you need to spend much more a few years down the road when you have to rebuild your website.</p>
                                    <p>Planning ahead can save you a large headache later down the road, and it is best to understand your options and be briefed with what is possible by an expert in the industry. For more information do contact me and I will be happy to give you a no strings attached consultation. From there you may decide which direction you would like to pursue.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default WhatTypeOfWebsiteYourCompanyNeeds;