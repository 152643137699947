import React, {useState} from "react";
import emailjs from 'emailjs-com';
import useInput from "../../hooks/use-input";
import {useSelector} from "react-redux";

const ContactTwo = () => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    window.dataLayer = window.dataLayer || [];

    const userKeyword = useSelector(state => state.user.keyword);
    const userCampaign = useSelector(state => state.user.campaign);
    const promoTimestamp = useSelector(state => state.user.promoTimestamp);

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'AW-620745259');

    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-620745259/IyNECPuP5OUCEKuk_6cC',
            'event_callback': callback
        });
        return false;
    }

    const validateEmail = input => {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(input);
    };

    const validatePhone = input => {
        const pattern = new RegExp(/^^(?:[+\d].*\d|\d)$/i);
        return pattern.test(input);
    };

    const {
        value: enteredName,
        isValid: nameIsValid,
        hasError: nameInputHasError,
        inputChangeHandler: nameInputChangeHandler,
        onFocusOutHandler: nameFocusOutHandler,
        reset: resetName,
    } = useInput(input => input.trim() !== '');

    const {
        value: enteredEmail,
        isValid: emailIsValid,
        hasError: emailInputHasError,
        inputChangeHandler: emailInputChangeHandler,
        onFocusOutHandler: emailFocusOutHandler,
        reset: resetEmail,
    } = useInput(validateEmail);

    // const {
    //     value: enteredSubject,
    //     inputChangeHandler: subjectInputChangeHandler,
    //     reset: resetSubject,
    // } = useInput(input => input);

    const {
        value: enteredPhone,
        isValid: phoneIsValid,
        hasError: phoneInputHasError,
        inputChangeHandler: phoneInputChangeHandler,
        onFocusOutHandler: phoneFocusOutHandler,
        reset: resetPhone,
    } = useInput(validatePhone);

    const {
        value: enteredMessage,
        isValid: messageIsValid,
        hasError: messageInputHasError,
        inputChangeHandler: messageInputChangeHandler,
        onFocusOutHandler: messageFocusOutHandler,
        reset: resetMessage,
    } = useInput(input => input.trim() !== '');

    let formIsValid = false;
    if (nameIsValid && emailIsValid && phoneIsValid && messageIsValid) {
        formIsValid = true;
    }
    let promoValidity;
    if (promoTimestamp) {
        if (parseInt(promoTimestamp) < Date.now()) {
            promoValidity = `Promo has expired on ${new Date(promoTimestamp).toLocaleString()}`;
        } else {
            promoValidity = `Promo will expire on ${new Date(promoTimestamp).toLocaleString()}`;
        }
    } else {
        promoValidity = 'User never had a promo.';
    }

    const [formSubmitting, setFormSubmitting] = useState(false);

    const sendEmail = e => {
        e.preventDefault(); // Prevents default refresh by the browser
        setFormSubmitting(true);
        emailjs.sendForm('zedpro_me', 'template_jd4uy8a', e.target, 'user_l5FusUMnfP6zQnDtQDRo1')
            .then((result) => {
                    alert("Message Sent, We will get back to you shortly", result.text);
                    gtag_report_conversion();
                    resetName();
                    resetEmail();
                    resetMessage();
                    resetPhone();
                    setFormSubmitting(false);
                },
                (error) => {
                    alert("An error occurred, Please try again", error.text);
                });
    };

    function gtag_phone_trigger() {
        gtag('event', 'conversion', {
            'send_to': 'AW-620745259/nRKICKL49vACEKuk_6cC',
        });
        _mtm.push({"event": "phone_trigger"});
        return true;
    };

    function gtag_email_trigger() {
        gtag('event', 'conversion', {
            'send_to': 'AW-620745259/o4mRCNeVzPACEKuk_6cC',
        });
        _mtm.push({"event": "email_trigger"});
        return true;
    };
    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <span className="subtitle">Let's Get in Touch</span>
                            <h2 className="title">Contact Us.</h2>
                            <div className="im_address">
                                <span>Call us directly:</span>
                                <a className="link im-hover" href="tel:+60104448626" onClick={gtag_phone_trigger}>+60104448626</a>
                            </div>
                            <div className="im_address">
                                <span>Office Number:</span>
                                <a className="link im-hover" href="tel:+60321819723" onClick={gtag_phone_trigger}>+60321819723</a>
                            </div>
                            <div className="im_address mt--5">
                                <span>Contact Email:</span>
                                <a className="link im-hover" href="mailto:contact@zedpro.me"
                                   onClick={gtag_email_trigger}>contact@zedpro.me</a>
                            </div>
                            <div className="im_address">
                                {/*<span>WhatsApp Us:</span>*/}
                                {/*<a className="link im-hover" href="tel:+60104448626" onClick={gtag_phone_trigger}>+60104448626</a>*/}
                                <a onClick={gtag_phone_trigger} target='_blank noopener noreferrer' href="https://wa.me/60104448626?text=Hey%20ZedPro%20Development%2C%20I%20am%20interested%20in%20your%20web%2Fsoftware%2Fmobile%20development%20services%2C%20please%20contact%20me%20as%20soon%20as%20you%20can."><img style={{marginTop: 10, marginBottom: 10}} alt="Chat on WhatsApp" src="/assets/images/whatsapp/WhatsAppButtonWhiteMedium.png" /></a>
                            </div>
                        </div>
                        <div className="form-wrapper">
                            <form onSubmit={sendEmail} id="contact-form">
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={enteredName}
                                        className={`${nameInputHasError && 'invalid'}`}
                                        onChange={nameInputChangeHandler}
                                        onBlur={nameFocusOutHandler}
                                        placeholder="Your Name *"
                                    />
                                </label>
                                {nameInputHasError && <p className="text-danger">You must enter a name.</p>}
                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={enteredEmail}
                                        className={`${emailInputHasError && 'invalid'}`}
                                        onChange={emailInputChangeHandler}
                                        onBlur={emailFocusOutHandler}
                                        placeholder="Your email *"
                                    />
                                </label>
                                {emailInputHasError && <p className="text-danger">You must enter a valid email.</p>}
                                {/*<label htmlFor="item03">*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        name="subject"*/}
                                {/*        id="item03"*/}
                                {/*        value={enteredSubject}*/}
                                {/*        onChange={subjectInputChangeHandler}*/}
                                {/*        placeholder="Write a Subject"*/}
                                {/*    />*/}
                                {/*</label>*/}
                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="phone"
                                        id="item03"
                                        value={enteredPhone}
                                        className={`${phoneInputHasError && 'invalid'}`}
                                        onChange={phoneInputChangeHandler}
                                        onBlur={phoneFocusOutHandler}
                                        placeholder="Phone Number *"
                                    />
                                </label>
                                {phoneInputHasError &&
                                    <p className="text-danger">You must enter a valid phone number.</p>}
                                <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={enteredMessage}
                                            className={`${messageInputHasError && 'invalid'}`}
                                            onChange={messageInputChangeHandler}
                                            onBlur={messageFocusOutHandler}
                                            placeholder="Message *"
                                        />
                                </label>
                                {messageInputHasError &&
                                    <p className="text-danger">You must enter a message to continue.</p>}
                                <input name="keyword" value={userKeyword} type="hidden"/>
                                <input name="campaign" value={userCampaign} type="hidden"/>
                                <input name="promo" value={promoValidity} type="hidden"/>
                                <button className='btn-default' type="submit"
                                        value="submit" name="submit"
                                        id="mc-embedded-subscribe" disabled={!formIsValid || formSubmitting}>Submit Now
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src="/assets/images/about/about-12.jpg" alt="trydo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactTwo;
