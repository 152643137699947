import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class AwesomeSearchEngineOptimizationTools extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Awesome Search Engine Optimization Tools']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Awesome Search Engine Optimization Tools' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Awesome Search Engine Optimization <br /> Tools</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 16, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h2>Top 6 Search Engine Optimization tools</h2>
                                    <p>There are plenty of awesome Search Engine Optimization tools that can help you rank your website higher on search engines. These tools range from Keyword planners and Backlink checkers, all the way to on-page SEO tools to make sure you didn’t miss anything important. SEO is a tricky game, to stay on top you must use every edge made available to you. Although SEO takes a long time to show results. With the right SEO strategy, you ensure that over time you will achieve the goals you set and rank optimally.</p>
                                    <h2><strong><strong>UberSuggest</strong></strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/ubersuggest.jpg" alt="UberSuggest"/>
                                    </div>
                                    <p>This is an excellent free SEO tool that is an awesome way to generate new keyword ideas. Using <a href="https://app.neilpatel.com/en/ubersuggest/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">UberSuggest</a> you can view the popularity of a keyword, as well as how competitive it is. This tool will also suggest other related keywords that could be better in terms of search volume and SEO difficulty. Acquired by entrepreneur and SEO guru <a href="//neilpatel.com" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Neil Patel</a> in 2019, he has since expanded its feature set. This tool will help you plan your blog posts and give you an idea of what people are searching for. It will also help you reduce the amount of energy you waste writing irrelevant posts. This will allow you to strengthen your website's grip on certain keywords.</p>
                                    <h2><strong>Ahrefs Backlink Checker</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/ahref-backlink-checker.jpg" alt="Ahrefs"/>
                                    </div>
                                    <p>As touched in a previous article on this site, backlinks are a vital part of any SEO strategy. However, backlinks are very subjective to the competition. When ranking for a keyword, you don't have to have the most backlinks in the world. You only need to beat your competitor in the first page of Google. When conducting SEO research to determine how many backlinks you will need, this tool is amazing. <a href="https://ahrefs.com/backlink-checker" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Ahrefs Backlink Checker</a> will provide you with several key metrics. It gives you a domain rating, the number of backlinks a site has, as well as how many referring domains link to a site. It also tells you the domain rating for the backlinks among other valuable metrics.</p>
                                    <h2><strong>Google Search Console</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/google-search-console.jpg" alt="Google Search Console"/>
                                    </div>
                                    <p>Many people neglect actually informing Google of their website. This means you will have to wait for Google and other search engines to crawl the pages on your website. This may take longer than if you had submitted your website and site map into <a href="https://search.google.com/search-console/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Google’s Search Console</a>. By doing this you are informing Google that you exist and to please index your website regularly. This is an important step especially for new websites, as it will help your website get out of the sandbox. Google places new websites into a sandbox for about 6 months. This is due to new websites having no credibility or trust as they could be spam sites.</p>
                                    <h2><strong>Google My Business</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/google-my-business.jpg" alt="Google My Business"/>
                                    </div>
                                    <p>Moving forward, after you have informed <a href="https://www.google.com/business/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Google</a> of your website doesn’t forget to submit your business into Google. Let Google know about your business and the industry you are in. Inform Google of your official address, phone number, and website. This will get you into more places that the normal search results. You can rank in the maps section as well, which will help further identify you among potential customers that are looking for you. It also helps Google differentiate between legitimate websites and spam. Be sure to verify your business with Google as well to maximize your SEO gain.</p>
                                    <h2><strong>MOZbar</strong></h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/mozbar-1024x67.jpg" alt="MOZbar"/>
                                    </div>
                                    <p>Mozbar is a free Google Chrome browser extension that gives you the domain authority of a website. With a free account, you can get plenty of awesome features, including but not limited to page and domain authority as well as a domain spam score. With a click of a button, you can also analyze the on-page elements, general attributes, and link metrics. The great part of this Google Chrome extension is that it works on Google’s search results, the domain and page authority is displayed below each result. Allowing you to get a general idea of the average domain authority required to rank for a particular keyword.</p>
                                    <h2><strong>Yoast SEO</strong>: The Best Search Engine Optimization Tool for WordPress</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/yoast-seo-recomendations.jpg" alt="Yoast SEO"/>
                                    </div>
                                    <p>According to data from web technology survey firm W3Techs, WordPress powers 30 percent of all websites. Yoast SEO is a perfect plugin for WordPress. Yoast helps you with SEO for your website but also with a detailed analysis of your on-page SEO as well. From a broad overview, it presents you with an easy to follow setup wizard. That in turn lets you input your social media pages, and other relevant data for your website. You can also customize your website's SEO title and logo through the wizard.</p>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/yoast-seo-title-and-meta-description.jpg" alt="Yoast Settings"/>
                                    </div>
                                    <p>Once finished with the wizard you can go into the Yoast settings and choose which pages will be indexed by Google. You can also enter your Google Search Console validation tag on the settings page. Yoast is terrific for on-page optimizations as it suggests and highlights things that you may have overlooked. It allows you to enter your target keyword and then lets you know what you must change to maximize your optimization. You can also customize your SEO title and meta description. There is even a tab where you can customize how your page will look like on social media sites if someone shares it.</p>
                                    <h3><strong>Above are some of the Awesome Search Engine Optimization tools you need to know about</strong></h3>
                                    <p>Although there are plenty of other tools than those listed above I feel you should start and focus on those first. The tools listed above will give you a head start in the marathon that is ranking your website into the first page of Google. In another blog post further down the road, we will touch on other tools that can help with your SEO strategy.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default AwesomeSearchEngineOptimizationTools;