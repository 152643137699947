import {useReducer} from "react";

const initialInputState = {
    value: '',
    isTouched: false
};

const inputStateReducer = (prevState, action) => {
    if (action.type === 'INPUT') {
        return {
            ...prevState,
            value: action.value
        };
    }
    if (action.type === 'TOUCH') {
        return {
            ...prevState,
            isTouched: true,
        }
    }
    if (action.type === 'RESET') {
        return {
            isTouched: false,
            value: ''
        }
    }
    return initialInputState;
}

const useInput = validateValue => {
    const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState)

    const inputIsValid = validateValue(inputState.value);
    const hasError = !inputIsValid && inputState.isTouched;

    const inputChangeHandler = e => {
        dispatch({
            type: 'INPUT',
            value: e.target.value
        })
    };
    const onFocusOutHandler = () => {
        dispatch({
            type: 'TOUCH',
        })
    };

    const reset = () => {
        dispatch({
            type: 'RESET',
        })
    };
    return {
        value: inputState.value,
        isValid: inputIsValid,
        hasError,
        inputChangeHandler,
        onFocusOutHandler,
        reset
    }
};

export default useInput;