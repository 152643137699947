const BlogContent = [
    {
        images: '15',
        title: 'Troubleshooting ArcGIS Enterprise Server Deployment',
        category: 'Server Administration',
        slug: 'troubleshooting-arcgis-enterprise-server-deployment'
    },
    {
        images: '01',
        title: 'Different ways of generating Website Traffic',
        category: 'Web Development',
        slug: 'different-ways-of-generating-website-traffic'
    },
    {
        images: '02',
        title: 'Why websites are the new Business Cards',
        category: 'Web Design',
        slug: 'why-websites-are-the-new-business-cards'
    },
    {
        images: '03',
        title: 'Why you need a personal website?',
        category: 'Web Design',
        slug: 'why-you-need-a-personal-website'
    },
    {
        images: '04',
        title: 'Install OnlyOffice Document Server with VestaCP',
        category: 'Linux',
        slug: 'install-onlyoffice-document-server-with-vestacp'
    },
    {
        images: '05',
        title: 'Top 5 essential pages in any Website',
        category: 'Web Design',
        slug: 'top-5-essential-pages-in-any-website'
    },
    {
        images: '06',
        title: 'Active vs Passive Websites',
        category: 'Web Design',
        slug: 'active-vs-passive-websites'
    },
    {
        images: '07',
        title: 'Top 10 Must-have WordPress Plugins',
        category: 'Web Development',
        slug: 'top-10-must-have-wordpress-plugins'
    },
    {
        images: '08',
        title: 'HTTP Status Codes',
        category: 'Web Development',
        slug: 'http-status-codes'
    },
    {
        images: '09',
        title: 'Different types of Web Development',
        category: 'Web Design',
        slug: 'different-types-of-web-development'
    },
    {
        images: '10',
        title: 'Awesome Search Engine Optimization Tools',
        category: 'SEO',
        slug: 'awesome-search-engine-optimization-tools'
    },
    {
        images: '11',
        title: 'Google ranking factors that you need to know',
        category: 'SEO',
        slug: 'google-ranking-factors'
    },
    {
        images: '12',
        title: 'Why is SEO (Search Engine Optimization) important?',
        category: 'SEO',
        slug: 'why-is-search-engine-optimization-important'
    },
    {
        images: '13',
        title: 'What type of Website your company needs?',
        category: 'Web Design',
        slug: 'what-type-of-website-your-company-needs'
    },
    {
        images: '14',
        title: 'Different Types of Websites',
        category: 'Web Design',
        slug: 'different-types-of-websites'
    }
]

export default BlogContent;