import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Zuba understood my organizations needs and was able to work with very little supervision. He was able to deliver on time, and within the estimated quotation that was originally provided.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Dr. Aida </span> - Founder, Little Steps Foundation.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>A one stop solution. He is always available for consultations and takes the required time to attend to my tech needs, both professionally and personally.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Sister Hazlina </span> - Founder, Hope Spring Foundation.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I was very pleased with ZedPro.Me for developing the website in a timely manner. The team was professional and skilled, going the extra mile to exceed my needs and requirements. </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Dr. Qais </span> - CEO, Future Expert Solutions.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;