import React from "react";

const CTA = ({title}) => {
    return (
        <>
            <h2 className="title" style={{textAlign: 'center', marginBottom: 50, fontSize: 54}}>{title}</h2>
            <div className="row row--25 justify-content-center">
                        <div className="service-45 service__style--1">
                            <div className="purchase-btn" style={{
                                display: 'flex',
                                gap: 15,
                                justifyContent: 'center'
                            }}>
                                <a className="rn-button-style--2 btn-secondary-color" href="/portfolio">View
                                    Portfolio</a>
                                <a className="rn-button-style--2 btn-primary-color" href="#contact">Inquiries</a>
                            </div>
                        </div>
                    </div>
        </>
    )
}

export default CTA;