import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class WhyIsSearchEngineOptimizationImportant extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Why is SEO (Search Engine Optimization) important?']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Why is SEO (Search Engine Optimization) important?' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Why is SEO (Search Engine Optimization) <br/> important?</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 6, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h2>Search Engine Optimization</h2>
                                    <p>Why is SEO (Search Engine Optimization) important? SEO is extremely important as these days almost everyone searching for things using them. Without search engines finding websites would be next to impossible. Therefore it is important to rank well in search engines so that people can find your website. If your website has no visitors, having it built was a waste of time.</p>
                                    <h2>Cost-saving</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/alexander-mils-lCPhGxs7pww-unsplash.jpg" alt="Cost Saving"/>
                                    </div>
                                    <p>If a website's SEO is poor, and it ranks badly, to get the traffic you will have to pay for ads. Paying for ads is an expensive solution that is not sustainable. With a good SEO ranking, you will gain visitors organically, since you will be found when your customers search for you. The best part is you won't have to <a href="https://ads.google.com/intl/en_my/home/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">pay per click</a>, you will get visitors organically. Therefore it is vital to work on your search engine optimization.</p>
                                    <h2>Long Term Strategy</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/jeshoots-com-fzOITuS1DIQ-unsplash.jpg" alt="Strategy"/>
                                    </div>
                                    <p>Search engine optimization takes time, and you will require a long term strategy. Since you cannot rank on the first page of Google within a day, a strategy is vital. Paying Google to stay on the first page, is a very expensive long term strategy. It would be much cheaper to build your website's ranking and reputation in the long run.</p>
                                    <h2>Avoiding dormant websites</h2>
                                    <p>One of the best reasons why you need SEO is to avoid a dormant and inactive website. If you have paid thousands of dollars for your brand new website, you want visitors. Without visitors, your shiny new website will just sit there, aimlessly. I am sure you do not want that for your website. That’s a waste of the money you spend building the site. An insult to the developer who made it, and plenty in lost revenue. Start optimizing your website today.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default WhyIsSearchEngineOptimizationImportant;