import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class WhyWebsitesAreTheNewBusinessCards extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Why websites are the new business cards']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Why websites are the new business cards' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Why websites are the new <br /> Business Cards</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Aug 22, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h2>Websites are the new business cards</h2>
                                        <p>As far as most of us can remember any respectable businessman would always carry a business card around with him. During a meetup or gathering either both formal and casual, he would without being shy extend and offer his business card. This was usually done as a way to market and network himself to expand his opportunities and open new doors for him. Today, on the other hand, you could say the same about websites. So let’s dive deeper into why websites are the new business cards of today.</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-02.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">To quickly summarize websites are the new business cards because they both aim to serve the same purpose. Websites are merely a new and updated form of business cards that offer up more real estate and allow for far more engagement. They also are an excellent avenue to market one’s self and list important achievements as well as relevant information about both company and persons. This makes websites as important if not more so than business cards in today’s day and age.</p>
                                        <h3>Are websites more important than business cards?</h3>
                                        <p>To say one is more important than the other would be wrong. They both are quite equally important, although if I had to choose I would go with a website over a business card. That being said having a website on your business card allows you to direct your intended audience. You can send them from a simple paper card that has very limited space to a website on which you can display as much information about yourself as you see fit. Websites quite often have short and easy to remember domain names, which in a pinch can be given out in lieu of a business card.</p>
                                        <h3>When did this trend start?</h3>
                                        <p>In the early 2000s, companies and certain individuals began creating websites to compliment the business cards that their employees would give out. As more and more people across the globe started gaining access to the internet, this trend flourished. Today you would find a hard time believing that a successful company or celebrity didn’t have their own website. In this era, most communication is conducted online and this is certainly a trend that is not going to disappear. Even the most computer illiterate individual will attest to the importance of having a website.</p>
                                        <h3>How are they like business cards?</h3>
                                        <p>Websites are in a certain manner similar to business cards as they are both avenues that inform its respective visitor and recipient about the individual and company. They contain information about who you are, what you do, and how you can be contacted. This is important as most people do not have photographic memories. If you had to write down or remember information about someone you just met, it would be an extra chore. Most often you would either find yourself missing a pen or just forget what you were told. Simply put, they both are easy ways to convey vital information.</p>
                                        <h3>What benefits do websites have over business cards?</h3>
                                        <p>Websites are far better than any business card as they have the ability to display much more information about you or your business. Think of it, a business card has two sides where else a website can have unlimited pages. Going one step further, websites can be made interactive and thus more engaging towards visitors. Lastly, having a website that can serve your customers 24/7 without issue, is far cheaper than having a 24/7 hotline that people can call. The 21st century is the age of the internet, no one can afford to be left behind.</p>
                                        <h3>If I have a website, does this mean I don’t need business cards anymore?</h3>
                                        <p>Websites and business cards are not mutually exclusive. Just because you have a website doesn’t mean you can’t have a business care and vice versa. In fact, they both can work together in synergy to help you achieve your goals. Offline you can pass around your business card with your website listed on it so that people can visit your website to learn more about you and your business. Where else online you can be found with a simple Google search, allowing you to dominate your competition. A marriage made in heaven.</p>
                                        <h2>Why websites are the new Business Cards</h2>
                                        <p>So above are some of the important reasons that answer why websites are the new business cards. In summary, websites allow you far greater freedom with regard to how much information you can display at once. It gives you the ability to interact with your audience in a way that surpasses business cards. A website is like a full-time sales agent working tirelessly to bring you leads. Finally successful businesses today will lack neither a website nor business cards as they are a vital part of a companies arsenal.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default WhyWebsitesAreTheNewBusinessCards;