import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

class FutureCloud extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Futurecloud.io']);
            _paq.push(['trackPageView']); 
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Futurecloud.io' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--5"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Nextcloud Hub</h2>
                                    <p>Military grade encryption </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">Futurecloud.io</h2>
                                                    <p className="description mt--30">A deployment of NextCloud which has many amazing features such as video/voice/chat conferencing, military-grade encryption on cloud storage and a lot more. Can be deployed on many different server operating systems and for up to thousands of users. Software is free and open source but deployment may come with some costs.</p>
                                                    <p className="description">The servers maintainence costs are minimal and are primarily related to hosting and the occasional upgrade and updates. Cost can be further reduces by self hosting the instance locally as opposed to on the cloud.</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Industry</span>
                                                        <h4>Technology</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4>Cloud</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Platform</span>
                                                        <h4>Nextcloud</h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://futurecloud.io">Launch The Site</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Where we came in</h4> 
                                                        <p className="description mt--30">Internally we had a need for a secure colaborative platform that would allow us to move away from traditional cloud providers like google and microsoft. Nextcloud intrigued us and we decided to deploy it.</p>
                                                        <p className="description mt--30">All in all we deployed the following configuration to our server:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> PHP-FPM Hosting</li>
                                                            <li><FiCheck /> Nextcloud Configuration</li>
                                                            <li><FiCheck /> Theming</li>
                                                            <li><FiCheck /> Performance Tuning</li>
                                                            <li><FiCheck /> Bug Fixes</li>
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/futurecloud/nextcloudhubthumbnail.png" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='I8GtygCoNcY' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/futurecloud/file-drag-and-drop.png" alt="Drag and Drop"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/futurecloud/serverwebui-2.png" alt="Web UI"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Related Work</span>
                                    <h2 className="title">Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/littlesteps">
                                            <img src="/assets/images/portfolio/related/related-littlesteps.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/littlesteps">LittleSteps</a></h4>
                                        <span className="category">Web Design</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/techz">
                                            <img src="/assets/images/portfolio/related/related-techz.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/techz">TechZ</a></h4>
                                        <span className="category">Blog</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default FutureCloud;
