import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

class TechZ extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'TechZ.io']);
            _paq.push(['trackPageView']); 
        return(
            <React.Fragment>
                <PageHelmet pageTitle='TechZ.io' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--7"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Tech Blog</h2>
                                    <p>Learning while teaching </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">TechZ.io</h2>
                                                    <p className="description mt--30">They say the best way to learn is to teach. We at ZedPro feel the same way. As we continously push ourselves to learn more things we felt it was necessary to not only learn but also document some of the things we figured out and blog about them. This way we have ready documentation if we or anyone else ever need to refer to it.</p>
                                                    <p className="description">We also felt that if some of our tech savy clients should ever feel adventurous they can not only expand their horizons but also acomplish small projects on their own. In this small way we are able to give back to the community.</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Industry</span>
                                                        <h4>Technology</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4>Blog</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Platform</span>
                                                        <h4>WordPress</h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://techz.io">Launch The Site</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Where we came in</h4> 
                                                        <p className="description mt--30">Internally we ran into problems where some times we would repeat certain tasks, so we felt it was important to keep documentations on our projects. Since we were going to have to write the documentation anyway, we decided to put it in a blog.</p>
                                                        <p className="description mt--30">All in all we deployed the following configuration to our server:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> WordPress Website Hosting</li>
                                                            <li><FiCheck /> WordPress Theme Customization</li>
                                                            <li><FiCheck /> Graphic Design</li>
                                                            <li><FiCheck /> Content Creation</li>
                                                            <li><FiCheck /> Social Media Account Creation</li>
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/techz/techz.jpg" alt="Web UI"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Related Work</span>
                                    <h2 className="title">Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                <div className="thumb">
                                        <a href="/aichatbot">
                                            <img src="/assets/images/portfolio/related/related-aipro.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/aichatbot">AiChatbot</a></h4>
                                        <span className="category">Artificial Intelligence</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/futurecloud">
                                            <img src="/assets/images/portfolio/related/related-futurecloud.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/futurecloud">FutureCloud</a></h4>
                                        <span className="category">Cloud Storage</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default TechZ;
