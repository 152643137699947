import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class ActiveVsPassiveWebsites extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Active vs Passive Websites']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Active vs Passive Websites' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Active vs Passive websites</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 30, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h2><strong>The purpose of Websites</strong></h2>
                                        <p>Websites can serve many purposes. They can be used for promotion, <a href="https://www.investopedia.com/terms/e/ecommerce.asp" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">e-commerce</a>, as a blog, or just your traditional website giving the visitor information about your company. Primarily websites fall under two categories, active and passive. So what is the difference between active vs passive websites?</p>
                                        <p><strong>Active websites as the name would suggest are constantly evolving, changing, and expanding. Old content is regularly moderated and new content is being added on a regular basis. Ranging from once a month all the way two multiple times in a day. Passive websites on the other hand are set and forget. Once designed and approved the owner doesn't feel the need to make any changes.</strong></p>
                                        <p>So which one is best for you? What type of website best suits your persons or organization? Let's go deeper into the similarities and differences as well as their advantages and disadvantages. Let's talk about what they are, who they are meant for when they are suitable, what do you need to do, and how much do they cost?</p>
                                        <h3><em><strong>Active websites</strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/techz.jpg" alt="Techz"/>
                                            </div>
                                        <h5 className="mt--40"><strong>What are they?</strong></h5>
                                        <p>An active website is a site that has content added on a regular basis. Old content is moderated and changed if necessary. Both new and old content of the site is updated constantly to reflect any possible changes in the outside world. Sites like these usually have a blog where new posts are created. Depending on the niche and the proprietor of the website, new content may be posted daily, weekly, or monthly. Examples of active websites are; sites with blogs, <a href="https://www.aljazeera.com/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">news sites</a>, etc..</p>
                                        <h5><strong>Who are they for?</strong></h5>
                                        <p>If you are looking to improve your search engine rankings, engage with your visitors, or generally attract more organic and recurring traffic then this type of website is for you. Websites like this often get up to a hundred thousand page views per month. This is great if you're trying to monetize a service or a product. If you wanted you could even monetize the website itself with ads. But in general, if you’re a business selling a product you might not want random advertisements on your site.</p>
                                        <h5><strong>When are they suitable?</strong></h5>
                                        <p>Active websites are best suited for companies or persons looking to expand and achieve more visitors organically. People don't like stale outdated websites, search engines even less so. Websites must be updated regularly to build authority and trustworthiness within their niche. Proprietors that wish to stay relevant need to keep active websites. Search engines love these kinds of sites as over time they gain authority and trust. Believe me, you want that search engine love. It will rank you high in the search engines and save you a lot of money in the long run.</p>
                                        <h5><strong>What do you need to do to achieve this type of website?</strong></h5>
                                        <p>The formula you need to follow is actually rather simple. Content is king! Quality content that is. There is no way around it. Either you will have to brush up on your writing skills, I know I had to. Or you can just hire a professional, but naturally, that will cost more. Posting regular high-quality content will build trust and <a href="https://moz.com/learn/seo/domain-authority" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">authority</a> for your domain. This in turn will convert into a steady fan-base of visitors that will find your site and keep coming to it. Your number one fan might even be Google in time, and this will lead you to rank number 1 in the search engine results. Who wouldn't want that?</p>
                                        <h5><strong>How much does it cost?</strong></h5>
                                        <p>While content is king, you will still need cash. Active websites are high maintenance as they require a lot of work from both web designers and content writers. You will also need a web developer to monitor the stability of your site. All that extra traffic tends to eat up resources, and you will need a good stable server to park your website at. But at the end of the day, the thousands of daily visitors should make it all worthwhile.</p>
                                        <h3><em><strong>Passive websites</strong></em></h3>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/aipro-fi.jpg" alt="AiChatbot"/>
                                            </div>
                                        <h5 className="mt--40"><strong>What are they?</strong></h5>
                                        <p>Passive websites are sites that don't change often or even at all for that matter. They are usually simple websites, with a home, about, contact, and maybe a product page. Once you take delivery of the website, you just forget about it. The website lies on your domain dormant and its sole purpose is to inform visitors of who you are, what you do, your address, and phone number. Examples of passive websites are; resume websites, corporate websites, etc..</p>
                                        <h5><strong>Who are they for?</strong></h5>
                                        <p>Primarily meant for people who don't need to or care about ranking in search engines for anything besides their own brand. If you have an already established brand or are planning on attracting visitors using advertisements or other promotional campaigns, this is the site for you. If you don’t want to have to worry about adding new content, managing writers, or having expensive hosting this is the type of website you should get. Passive websites are cost-effective, simple to run, and easy to host.</p>
                                        <h5><strong>When are they suitable?</strong></h5>
                                        <p>All that matters is you have a website. You can use it on your social media pages, and on your business card. Sometimes people look to this type of website to achieve legitimacy, as nothing says I am open for business than a website. Businesses without websites do not often carry the confidence of customers. When was the last time you bought a product or used a service that did not have a website? This website is best suited for companies or persons that just want to add the bare minimum or credibility for themselves.</p>
                                        <h5><strong>What do you need to do to achieve this type of website</strong><strong>?</strong></h5>
                                        <p>As I have touched on above, to commission a passive website for yourself doesn’t take much all you really need is an all in one web designer that can take care of everything. Since passive websites are simple and don’t contain much content. You don’t need a content writer, just pass along the information and media that you would like to be added to your website, and you will be done in a few weeks. Be sure to provide your web designer with everything he needs in a timely fashion and your website will be done in next to no time.</p>
                                        <h5><strong>How much does it cost</strong><strong>?</strong></h5>
                                        <p>There is usually a big misunderstanding when clients come to me asking to build a website. Clients often think the largest cost is designing the website itself. However, most of the cost for a website will be spent not on developing the site but in other areas. These include advertising, promotion campaigns, and content management that often includes hiring social media managers and content writers. With passive sites, all you need to worry about is the cost of building the website and the yearly fees that include hosting and domain renewal. Because of this, a passive website is much cheap than an active website.</p>
                                        <h6><em><strong>Now you know the difference between Active vs Passive Websites. You can decide for yourself which best meets the needs and goals you would like to accomplish. Neither type of site is better than the other, they both have their own place on the world wide web. Do comment and let me know what you think, do you have a preference between active vs passive websites?</strong></em></h6>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default ActiveVsPassiveWebsites;