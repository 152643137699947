import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image17 = <img src="/assets/images/portfolio/arcgis-portfolio-pdrm.jpg" alt="PDRM ArcGIS Enterprise Deployment" />;
const Portfolio_image18 = <img src="/assets/images/portfolio/arcgis-portfolio-lgm.jpg" alt="LGM ArcGIS Enterprise Deployment" />;
const Portfolio_image19 = <img src="/assets/images/portfolio/arcgis-portfolio-suk-kedah.jpg" alt="SUK Kedah ArcGIS Enterprise Deployment" />;

const PortfolioList = [
    {
        image: Portfolio_image17,
        category: 'ArcGIS Enterprise',
        title: 'PDRM Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/pdrm-software-deployment"
    },
    {
        image: Portfolio_image18,
        category: 'ArcGIS Enterprise',
        title: 'LGM Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/lgm-software-deployment"
    },
    {
        image: Portfolio_image19,
        category: 'ArcGIS Enterprise',
        title: 'SUK Kedah Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/suk-kedah-software-deployment"
    },
]

class Portfolio extends Component{
    render(){
        let title = 'Our Recent Works',
        description = 'Here are some of the few projects we have worked on, <br /> ranging from private cloud storage to AI chatbots.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Our project</span>
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to={value.url}>
                                                    {value.image}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to={value.url}>{value.category}</Link>
                                                    </div>
                                                    <h4 className="title"><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to={value.url}></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row  mt--60">
                            <div className="col-12">
                                <div className="text-center">
                                    <Link className="rn-button-style--2 btn-primary-color" to="/portfolio">See more</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;
