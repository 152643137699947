import React from "react";

const Bullet = ({title, image, subTitle, list}) => {
    return (
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src={image} alt="Bullet List"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner" style={{color: '#f3f3f3'}}>
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <p className="description marginBottomUnset" style={{color: '#f3f3f3'}}>{subTitle}</p>
                                    <ul>
                                        {list.map((l, i) =>
                                            <li key={i}>
                                                {l}
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Bullet;
