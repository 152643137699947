import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

class Cushionflute extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Cushionflute.com']);
            _paq.push(['trackPageView']);
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Cushionflute.com' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--14"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Cushionflute Carton</h2>
                                    <p>Recycle & Reuse</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h3 className="title">Cushionflute.com</h3>
                                                    <p className="description mt--30">Cushionflute Pty Ltd is proudly an Australian manufacturing company with its production facilities based in Melbourne. The Cushionflute (CF) Carton is our exciting new innovation. It is 100% water & leak proof and is also Flat Packed. The CF carton is a two piece (base and lid) product offering both convenience and excellent protection, whilst also offering many more benefits.</p>
                                                    <p className="description">Their CF carton is designed to reduce manual handling and the spoilage of goods whilst providing long term cost savings.</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Industry</span>
                                                        <h4>Manufacturing</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4>Website</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Platform</span>
                                                        <h4>WordPress</h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://cushionflute.com" target = "_blank" rel = "noopener noreferrer">Launch The Site</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Where we came in</h4>
                                                        <p className="description mt--30">Ms Jolene contacted us to discuss how they could move forward with redesigning their current website for their manufacturing company. They were looking for an SEO oriented developer to take on this project.</p>
                                                        <p className="description mt--30">All in all we provided the following services to her company:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> WordPress Website Hosting</li>
                                                            <li><FiCheck /> WordPress Theme Customization</li>
                                                            <li><FiCheck /> Graphic Design</li>
                                                            <li><FiCheck /> Search Engine Optimization</li>
                                                            <li><FiCheck /> Social Media Account Creation</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/cushionflute/cushionflute.jpg" alt="website"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Related Work</span>
                                    <h2 className="title">Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/fhmalaysia">
                                            <img src="/assets/images/portfolio/related/related-fhmalaysia.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/fhmalaysia">FHMalaysia.com</a></h4>
                                        <span className="category">Web Design</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/thinkat">
                                            <img src="/assets/images/portfolio/related/related-thinkat.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/thinkat">ThinkAt</a></h4>
                                        <span className="category">Web Design</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Cushionflute;
