import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiHelpCircle, FiSearch, FiTrendingUp} from "react-icons/fi";
import {useLocation} from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from ".//components/Footer";


import Helmet from "../component/common/Helmet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import {FaWordpressSimple} from "react-icons/fa";
import Hero from "./components/Hero";
import Trusted from "./components/Trusted";
import Bullet from "./components/Bullet";
import Platform from "./components/Platform";
import {useDispatch} from "react-redux";
import {userActions} from "../store/user";

const capitalize = string => {
    if(string){
        const stringList = string.split(" ");
        const finalString = stringList.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return finalString
    } else {
        return undefined
    }
}

const WebsiteDevelopment = () => {
    const [promoActive, setPromoActive] = useState(true);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const query = useQuery();
    const keyword = capitalize(query.get('keyword'));
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', keyword ? keyword : 'Website Development Services']);
    _paq.push(['trackPageView']);

    // Remove third party tracking params
    useEffect(() => {
        window.history.replaceState(null, `${keyword ? 'Website Development Services' : keyword} | e-Commerce. WordPress | Kuala Lumpur`, keyword ? `/website-development${keyword&&`?keyword=${keyword}`}` : '/website-development')
    }, [keyword]);

    useEffect(() => {
        if(keyword){
            dispatch(userActions.setKeyword(keyword));
        }
    }, [keyword, dispatch])
    useEffect(() => {
        dispatch(userActions.setCampaign('Website Leads'));
    }, [dispatch])

    const ServiceList = [
        {
            icon: <FaWordpressSimple/>,
            title: 'WordPress CMS',
            description: 'With a CMS, future edits and additions can be done seamlessly.'
        },
        {
            icon: <FiTrendingUp/>,
            title: 'Premium Theme',
            description: 'Don\'t take the chance, see exactly what you\'re getting before you commit.'
        },
        {
            icon: <FiSearch/>,
            title: 'SEO Friendly',
            description: 'Get ranked on the first page of google and watch as your leads increase.'
        },
        {
            icon: <FiHelpCircle/>,
            title: 'Dedicated Support',
            description: 'We are always ready and willing to assist you should you need any help.'
        },
    ]

    return (
        <div className="active-dark">
            <Helmet pageTitle={keyword ? keyword : 'Website Development Services'}/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"
                    isLandingPage={keyword}/>

            {/* Start Hero Area */}
            <Hero
                page={'website'}
                promoActive={promoActive}
                setPromoActive={setPromoActive}
                keyword={keyword}
                title='Complete Website Package'
                price={3500}
                description='Take your business to the next level, Improve your online presence by having a modern and SEO friendly website made for the lowest price.'
                hook='100% Malaysian.'
            />
            {/* End Hero Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--1" style={{
                padding: '100px 50px'
            }}>
                <Trusted/>
            </div>
            {/* End Service Area  */}

            {/*        /!* Start Service Area *!/*/}
            <div className="service-wrapper service-white" style={{
                padding: '100px 50px'
            }}>
                <Services
                    serviceList={ServiceList}
                    title='One Stop Solution'
                />
            </div>
            {/*        /!* End Service Area *!/*/}
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Bullet
                    image='/assets/images/landing/wordpress%20themes.png'
                    title='Why use the WordPress platform?'
                    subTitle='WordPress comes with several key benefits:'
                    list={['Security Updates', 'User friendly Backend', 'Rich Plugin Ecosystem']}
                />
            </div>
            <div
                className="align-items-center bg_color--5"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Platform
                    image='/assets/images/landing/themeforest%20premium%20themes.png'
                    title='Why use a premium theme?'
                    description='Everything has already been designed, so why reinvent the wheel. Using premium themes not only do you see first hand how your site will generally look and feel, but you also can rest well knowing that the underline code is also secure.'
                    ctaTitle='Premium Themes'
                    ctaLink='https://themeforest.net/category/wordpress'
                />
            </div>
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
                id='contact'
            >
                <Contact
                    title='Schedule a free consultation'
                    description={<span>Jump on a quick zoom call with us, and we will discuss your requirements and propose a few premium themes. Upon confirmation we will start building your site using the premium theme you selected and the content that you provide. <br/> <br/> Then within a few weeks we will be ready for the first round of amendments. Lastly a quick zoom training session to familiarise your staff with how to make quick edits.</span>}
                    phoneTrigger='AW-620745259/nRKICKL49vACEKuk_6cC'
                    emailTrigger='AW-620745259/o4mRCNeVzPACEKuk_6cC'
                    formTrigger='AW-620745259/IyNECPuP5OUCEKuk_6cC'
                    isLandingPage={keyword}
                />
            </div>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer
                ctaTitle={`From A to Z${promoActive ? ' from only RM5,000' : ''}`}
            />

        </div>
    )
}
export default WebsiteDevelopment;
