import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

class MuhajirClinic extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'MuhajirClinic.com']);
            _paq.push(['trackPageView']); 
        return(
            <React.Fragment>
                <PageHelmet pageTitle='MuhajirClinic.com' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Klinik Amal Muhajir</h2>
                                    <p>Serdang's first charity clinic. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">MuhajirClinic.com</h2>
                                                    <p className="description mt--30">Klinik Amal Muhajir holds the privilege of being Serdang's first charity clinic. Realizing the need for basic medical care for the migrant population, Little Steps and Hope Spring decided to get together and make an attempt to provide for this unfortunate community. This charity, free clinic, opened on the 18th of July, 2020.</p>
                                                    <p className="description">The clinic's overheads and running costs are fully funded by generous public donations. We rely on your support to help provide vital healthcare for the refugee and asylum-seeker population in Malaysia.</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Industry</span>
                                                        <h4>Healthcare</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4>Website</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Platform</span>
                                                        <h4>WordPress</h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://muhajirclinic.com">Launch The Site</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Where we came in</h4> 
                                                        <p className="description mt--30">Dr Aida met with us to discuss how she could move foward with creating a website for her clinic in Jan 2020. After some discussion we settled on designing a website from top to bottom.</p>
                                                        <p className="description mt--30">All in all we provided the following services to her foundation:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> WordPress Website Hosting</li>
                                                            <li><FiCheck /> WordPress Theme Customization</li>
                                                            <li><FiCheck /> Graphic Design</li>
                                                            <li><FiCheck /> Search Engine Optimization</li>
                                                            <li><FiCheck /> Social Media Account Creation</li>
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/muhajirclinic/muhajircliniclogothumbnail.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='5vZjn43vg_k' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/muhajirclinic/muhajirclinic-1.jpg" alt="Exam Room"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/muhajirclinic/muhajirclinic-2.jpg" alt="Pharmacy"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Related Work</span>
                                    <h2 className="title">Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/littlesteps">
                                            <img src="/assets/images/portfolio/related/related-littlesteps.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/littlesteps">LittleSteps</a></h4>
                                        <span className="category">Web Design</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/hopespring">
                                            <img src="/assets/images/portfolio/related/related-hopespring.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/hopespring">HopeSpring</a></h4>
                                        <span className="category">Web Design</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default MuhajirClinic;
