import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
// import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 3.15622,
            lng: 101.70537
        },
        zoom: 16,
    };

    render(){
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Contact']);
            _paq.push(['trackPageView']);
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-620745259');
        function gtag_phone_trigger() {
            gtag('event', 'conversion', {
                'send_to': 'AW-620745259/nRKICKL49vACEKuk_6cC',
            });
            _mtm.push({"event": "phone_trigger"});
            return true;
        };
        function gtag_email_trigger() {
            gtag('event', 'conversion', {
                'send_to': 'AW-620745259/o4mRCNeVzPACEKuk_6cC',
            });
            _mtm.push({"event": "email_trigger"});
            return true;
        };
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact Us</h2>
                                    <p>Get a free, 1 hour <b>no strings attached</b> consultation now.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 text-center">
                                    <span className="subtitle">We are based at</span>
                                    <h2 className="title">Our Contact Information</h2>
                                    <p className="description">You can contact us using the methods below, <br /> via whatsapp, telegram, or email.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+60104448626" onClick={gtag_phone_trigger}>Tel: +60104448626</a></p>
                                        <p><a href="tel:+60321819723" onClick={gtag_phone_trigger}>Tel: +60321819723</a></p>
                                        {/*<p><a href="tel:+60104448626" onClick={gtag_phone_trigger}>WhatsApp: +60104448626</a></p>*/}
                                        <a onClick={gtag_phone_trigger} target='_blank noopener noreferrer' href="https://wa.me/60104448626?text=Hey%20ZedPro%20Development%2C%20I%20am%20interested%20in%20your%20web%2Fsoftware%2Fmobile%20development%20services%2C%20please%20contact%20me%20as%20soon%20as%20you%20can."><img style={{marginTop: 2, marginBottom: 2}} alt="Chat on WhatsApp" src="/assets/images/whatsapp/WhatsAppButtonWhiteMedium.png" /></a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:contact@zedpro.me" onClick={gtag_email_trigger}>contact@zedpro.me</a></p>
                                        <p><a href="mailto:help@zedpro.me" onClick={gtag_email_trigger}>help@zedpro.me</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>1-23-5, Menara Bangkok Bank, <br /> Berjaya Central Park, Jalan Ampang, Kuala Lumpur, 50450</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key:"AIzaSyDnHkoBWRTOXAr0PHVfRgFkvEbgfEiXHMU" }}
                        options={map => ({ mapTypeId: map.MapTypeId.HYBRID})}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            // lat={2.920162986}
                            // lng={101.652997388}
                            lat={3.15622}
                            lng={101.70537}
                            text="ZedPro"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
                {/* End Contact Map  */}


                {/* Start Brand Area */}
                {/* <div className="rn-brand-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact
