import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class DifferentTypesofWebDevelopment extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Different types of Web Development']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Different types of Web Development' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Different types of <br /> Web Development</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 20, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h2>Three main types of Web Development</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/joshua-aragon-EaB4Ml7C7fE-unsplash.jpg" alt="Blog Images"/>
                                    </div>
                                    <p>The three mains types of <a href="https://www.wikiwand.com/en/Web_development" target="_blank" rel="noreferrer noopener nofollow" aria-label="undefined (opens in a new tab)">web development</a> are front end, back end, and full-stack. These words are frequently thrown around but what do they mean? What are the differences between the different types of web development? Below we will touch a little on what these words mean, and which type of developer you need.</p>
                                    <h4><strong>Front End</strong> Web Development</h4>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/luke-peters-B6JINerWMz0-unsplash720.jpg" alt="By Luke"/>
                                    </div>
                                    <p>The front end is essentially what the client sees. When you or anyone loads up a website, you are faced with some pages that include some content and pictures. The first page and all the other pages you see are what make up the front end. This includes the about us, contact, and product pages. The front end is the user experience of what you can see touch and interact with. Basically what you see is what you get.</p>
                                    <p>All that beautiful design language, the color schemes, and the animations are done by front end developers. If you are looking to add or change anything on your website, this would be the type of development you will need. They also handle the design of the user interface. This leads to a smooth and pleasant experience for website visitors. They are more or less what you would refer to as web designers.</p>
                                    <h4><strong>Back End</strong> Web Development</h4>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/science-in-hd-iPrjQEDnNEY-unsplash720.jpg" alt="By Science in HD"/>
                                    </div>
                                    <p>On the other side of things, the back end is where all the magic happens. Here you have all the hidden aspects that the client doesn't see, yet is very important. The back end is essentially the server-side of things. All the functionality of a website or an app happens here. Such as when you click a button or submit a form. If a customer adds a product to their shopping cart, or when they try to check out.</p>
                                    <p>Back end developers ensure that all the programming that needs to be there for your website to work exists. Without a back end, even if you managed to load your website. None of its links, buttons, or functions would do anything meaningful. Server administration would also fall under this category. Back end developers also maintain and fix any bugs in the code. You can think of <a href="https://www.wikiwand.com/en/Software_bug" target="_blank" rel="noreferrer noopener nofollow" aria-label="undefined (opens in a new tab)">bugs</a> as mistakes that break your website. Believe me, you don't want any of that.</p>
                                    <h4><strong>Full Stack</strong> Web Development</h4>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/christopher-gower-m_HRfLhgABo-unsplash.jpg" alt="By Christopher"/>
                                    </div>
                                    <p>Now full-stack web developers are the supermen of the web design world. They don't really specialize in either the front end or the back end. These developers do double the work, they are well versed in the front end, back end, and everything in between. They will perform the task of many people. Such as ranging from user interface design, user experience design all the way to server-side maintenance, and web hosting setup.</p>
                                    <p>They will comb through the code to make sure there isn’t a comma or semi-colon that shouldn't be there. They will also go thought the content of your website to make sure there aren't any spelling mistakes. Full-stack developers will optimize your website on both the front end and back end to ensure it loads quickly and that your website is light. If you are building a website from scratch, go with a full-stack web developer. You can test how well a website is optimized using Google's <a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">PageSpeed Insights</a> tool.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default DifferentTypesofWebDevelopment;