import React from "react";
import CTA from "./CTA";


const Footer = ({ctaTitle}) => {
    const year = new Date().getFullYear();
    return (
        <React.Fragment>
            <footer className="footer-area footer-style-01 bg_color--6">
                <div className="im-call-to-action-area im-separator" style={{
                    padding: '100px 50px'
                }}>
                    <CTA
                        title={ctaTitle}
                    />
                </div>
                {/* Start Footer Area  */}
                <div className="footer-wrapper ptb--70">
                    <div className="container">
                        <div className="text-center text-white">
                            <p>Copyright © {year} ZedPro Development. All rights reserved.</p>
                        </div>
                    </div>
                </div>
                {/* End Footer Area  */}
            </footer>
        </React.Fragment>
    )
}

export default Footer;