import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class DifferentTypesOfWebsites extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Different Types of Websites']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Different Types of Websites' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Different Types of <br/> Websites</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />June 22, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h2>Drag and Drop Websites</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/wix.jpg" alt="Drag and Drop"/>
                                    </div>
                                    <p>These types of websites are the easiest to build. They simply entail picking a template and then dragging and dropping elements into your page. Best for novice web designers, or people with low initial budgets. They have a fixed feature set that cannot be changed. Examples of these sites are <a href="https://www.wix.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Wix</a> and <a href="https://www.squarespace.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Squarespace</a>.</p>
                                    <p>These websites are hassle-free, come with hosting built-in and you do not have to worry about downtime. It even might come with a free domain if you pick the right package. However, unlike other types of websites, paid packages often have relatively expensive yearly fees.</p>
                                    <h2>Content Management System based Websites</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/fikret-tozak-rfNLa1HL7eY-unsplash.jpg" alt="CMS Based"/>
                                    </div>
                                    <p>Websites based on <a href="https://en.wikipedia.org/wiki/Content_management_system" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">content management systems</a> or CMS are the next logical step. They offer elements like drag and drop functionality that is free except for the hosting. Although if you choose to have a web designer build it for you, there will be an upfront fee. Once built and setup, you only have to pay for your domain and hosting.</p>
                                    <p>As the hosting required for these sites are very boilerplate, you can get them for cheap. They also come with managed hosting plans. As the name suggests, they include a management system. This system is called a back-end and changes can be made with relative ease. Some examples of CMSs' are <a href="https://wordpress.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">WordPress</a>, <a href="https://www.joomla.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Joomla</a>, and <a href="https://www.drupal.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Drupal</a>.</p>
                                    <h2>Framework based Websites</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/alvaro-reyes-fSWOVc3e06w-unsplash.jpg" alt="Framework based"/>
                                    </div>
                                    <p>Frameworks help streamline and speed up the process of writing code from scratch. Yes! you read that correctly, no more drag and drop. Now we build things from the ground up. Of course, this means your website will look exactly the way you want it to. While it is much more technical and time-consuming, and naturally more expensive but you get more features.</p>
                                    <p>These frameworks are extremely modular and you can build any type of website using them. There is no end to how complicated they can get. As your business needs grow so can your website’s feature set expand to meet them? There is no back-end here unless you have one made, which incurs more costs of course. Any changes must be made on the source code level. Some examples of these frameworks are <a href="https://www.djangoproject.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Django</a>, <a href="https://reactjs.org/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">React</a>, and <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">Bootstrap</a>.</p>
                                    <h2>Static Websites</h2>
                                    <div className="thumbnail-padding">
                                            <img src="/assets/images/blog/igor-miske-JVSgcV8_vb4-unsplash.jpg" alt="Static Site"/>
                                    </div>
                                    <p>All websites run on <a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">HTML</a> and <a href="https://www.w3.org/Style/CSS/Overview.en.html" target="_blank" rel="noreferrer noopener" aria-label="undefined (opens in a new tab)">CSS</a>. However static websites only run on HTML and CSS, there is no back-end, and everything must be coded. There isn't any advanced functionality, no databases, and no login pages. What you see is what you get. No funny business.</p>
                                    <p>Best suited for very simple websites, that have simple needs. The hosting requirements for it are very small. While the upfront cost of development might be high, everything has to be written from scratch. Then once it's done, you can forget about it for years.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default DifferentTypesOfWebsites;