import React from "react";

const Trusted = () => {
    return (
        <div className="container">
            <div className="section-title" style={{
                display: "flex",
                justifyContent: 'center',
                marginBottom: 50
            }}>
                <span className="subtitle subtitleMod--medium">TRUSTED BY</span>
            </div>
            <div style={{
                display: "flex",
                justifyContent: 'center',
                gap: 40,
                flexWrap: 'wrap'
            }}>
                <img src='/assets/images/client/velosi-logo-white.png' style={{
                    height: 100,
                    padding: '0 46px'
                }}
                alt='velosi'
                />
                <img src='/assets/images/client/logo transparent png large.png' style={{
                    height: 100,
                    padding: '0 39px'
                }}
                     alt='be lifestyles'
                />
                <img src='/assets/images/client/cropped-Thinkat-logo-RGB-png-margins.png' style={{
                    height: 100
                }}
                     alt='thinkat'
                />
            </div>
        </div>
    )
}
export default Trusted;