import React from "react";

const Services = ({serviceList, title}) => {
    return (
        <>
            <h2 className="title" style={{textAlign: 'center', marginBottom: 50}}>{title}</h2>
            <div className="row row--25">
                {serviceList.map( (val , i) => (
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                        <div className="service-45 service__style--1">
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title">{val.title}</h4>
                                <p>{val.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default Services;