import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiHelpCircle, FiTrendingUp} from "react-icons/fi";
import {useLocation} from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from ".//components/Footer";


import Helmet from "../component/common/Helmet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import {AiOutlineMobile} from "react-icons/ai";
import Hero from "./components/Hero";
import Trusted from "./components/Trusted";
import Bullet from "./components/Bullet";
import Platform from "./components/Platform";
import {useDispatch} from "react-redux";
import {userActions} from "../store/user";
import {IoSpeedometerOutline} from "react-icons/io5";


const capitalize = string => {
    if(string){
        const stringList = string.split(" ");
        const finalString = stringList.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return finalString
    } else {
        return undefined
    }
}


const WebAppDevelopment = () => {
    const [promoActive, setPromoActive] = useState(true);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const query = useQuery();
    const keyword = capitalize(query.get('keyword'));
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', keyword ? keyword : 'Web App Development Services']);
    _paq.push(['trackPageView']);

    // Remove third party tracking params
    useEffect(() => {
        window.history.replaceState(null, `${keyword ? 'Web App Development Services' : keyword} | ReactJS, Laravel | Kuala Lumpur`, keyword ? `/webapp-development${keyword&&`?keyword=${keyword}`}` : '/webapp-development')
    }, [keyword]);

    useEffect(() => {
        if(keyword){
            dispatch(userActions.setKeyword(keyword));
        }
    }, [keyword, dispatch])
    useEffect(() => {
        dispatch(userActions.setCampaign('Web App Leads'));
    }, [dispatch])

    const ServiceList = [
        {
            icon: <IoSpeedometerOutline/>,
            title: 'Blazing Fast',
            description: 'Web apps are loaded completely in the background making them quick and responsive.'
        },
        {
            icon: <FiTrendingUp/>,
            title: 'Premium Code',
            description: 'Clean and efficient code ensures that your app is fast, secure and user friendly.'
        },
        {
            icon: <AiOutlineMobile/>,
            title: 'Mobile Friendly',
            description: 'A mobile first approach ensures that your web app looks and feels just like a mobile application.'
        },
        {
            icon: <FiHelpCircle/>,
            title: 'Dedicated Support',
            description: 'We are always ready and willing to assist you should you need any help.'
        },
    ]
    return (
        <div className="active-dark">
            <Helmet pageTitle={keyword ? keyword : 'Web App Development Services'}/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"
                    isLandingPage={keyword}/>

            {/* Start Hero Area */}
            <Hero
                page={'webapp'}
                promoActive={promoActive}
                setPromoActive={setPromoActive}
                keyword={keyword}
                title='Complete Web App Package'
                price={20000}
                description='Take your business to the next level, Streamline your business processes & improve your productivity by having a nextJS web app made for the lowest price.'
                hook='100% Malaysian.'
            />
            {/* End Hero Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--1" style={{
                padding: '100px 50px'
            }}>
                <Trusted/>
            </div>
            {/* End Service Area  */}

            {/*        /!* Start Service Area *!/*/}
            <div className="service-wrapper service-white" style={{
                padding: '100px 50px'
            }}>
                <Services
                    serviceList={ServiceList}
                    title='One Stop Solution'
                />
            </div>
            {/*        /!* End Service Area *!/*/}
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Bullet
                    image='/assets/images/landing/nextjs.webp'
                    title='Why use the nextJS framework?'
                    subTitle='NextJS comes with several key benefits:'
                    list={['Front & Backend', 'Highly Customizable', 'Improved SEO']}
                />
            </div>
            <div
                className="align-items-center bg_color--5"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Platform
                    image='/assets/images/portfolio/verdas/verdas.jpg'
                    title='What is a web app?'
                    description='An applications that run on a web browser. It differs from regular website in that all static data is compressed and pre-downloaded to the device upon initial load. Ensuring that navigating between pages of the app doesn’t require neither a refresh nor loading redundant data thus making them blazing fast.'
                    ctaTitle='More about Web Apps'
                    ctaLink='https://www.indeed.com/career-advice/career-development/what-is-web-application'
                />
            </div>
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
                id='contact'
            >
                <Contact
                    title='Schedule a free consultation'
                    description={
                    <span>
                        Jump on a quick zoom call with us, and we will discuss your requirements and propose a few solutions.
                        Upon confirmation we will start building your app based upon the pre-agreed scope and specifications.
                        <br/> <br/>
                        Then within a few months we will be ready for the first round of amendments.
                        Lastly a quick zoom training session to familiarise your staff with how to use the app.
                    </span>
                }
                    phoneTrigger='AW-620745259/nRKICKL49vACEKuk_6cC'
                    emailTrigger='AW-620745259/o4mRCNeVzPACEKuk_6cC'
                    formTrigger='AW-620745259/IyNECPuP5OUCEKuk_6cC'
                    isLandingPage={keyword}
                />
            </div>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer
                ctaTitle={`From A to Z${promoActive ? ' from only RM20,000' : ''}`}
            />

        </div>
    )
}
export default WebAppDevelopment;
