import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiCode, FiHelpCircle, FiRepeat, FiServer} from "react-icons/fi";
import {useLocation} from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from "../LandingPages/components/Footer";


import Helmet from "../component/common/Helmet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Hero from "./components/Hero";
import Bullet from "./components/Bullet";
import Platform from "./components/Platform";
import {useDispatch} from "react-redux";
import {userActions} from "../store/user";
import Brand from "../elements/Brand";


const capitalize = string => {
    if(string){
        const stringList = string.split(" ");
        const finalString = stringList.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return finalString
    } else {
        return undefined
    }
}


const ScriptAutomation = () => {
    const [promoActive, setPromoActive] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const query = useQuery();
    const keyword = capitalize(query.get('keyword'));
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', keyword ? keyword : 'Script Based Automation Services']);
    _paq.push(['trackPageView']);

    // Remove third party tracking params
    useEffect(() => {
        window.history.replaceState(null, `${keyword ? 'Script Based Automation Services' : keyword} | Deployment & Maintenance | Kuala Lumpur`, keyword ? `/script-based-automation${keyword&&`?keyword=${keyword}`}` : '/script-based-automation')
    }, [keyword]);

    useEffect(() => {
        if(keyword){
            dispatch(userActions.setKeyword(keyword));
        }
    }, [keyword, dispatch])
    useEffect(() => {
        dispatch(userActions.setCampaign('Script Based Automation Leads'));
    }, [dispatch])

    const ServiceList = [
        {
            icon: <FiServer/>,
            title: 'Deployment',
            description: 'On premise effective and efficient deployment around Malaysia. Secure and trusted.'
        },
        {
            icon: <FiRepeat/>,
            title: 'Automation',
            description: 'Automating mundane tasks and performing high volume data cleaning effectively and efficiently.'
        },
        {
            icon: <FiCode/>,
            title: 'Development',
            description: 'Custom API and database integrations with multiple technological stacks are available.'
        },
        {
            icon: <FiHelpCircle/>,
            title: 'Dedicated Support',
            description: 'While things rarely go wrong, they always can, our team is ready to assist if they ever do.'
        },
    ]
    return (
        <div className="active-dark">
            <Helmet pageTitle={keyword ? keyword : 'Script Based Automation Services'}/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"
                    isLandingPage={keyword}/>

            {/* Start Hero Area */}
            <Hero
                page="script"
                promoActive={promoActive}
                setPromoActive={setPromoActive}
                keyword={keyword}
                title='Script Based Automation Services'
                price={3000}
                description='Take your business to the next level, streamline your workflow by automating basic or high level tasks using scripts that process and clean huge amounts of data within minutes. Suitable languages for script development include javascript, python, and php.'
                hook='Database and API intergration are available. 100% Malaysian.'
            />
            {/* End Hero Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--1" style={{
                padding: '100px 50px'
            }}>
                {/*<Trusted/>*/}
                <div className="section-title" style={{
                    display: "flex",
                    justifyContent: 'center',
                    marginBottom: 50
                }}>
                    <span className="subtitle subtitleMod--medium">DEPLOYED AT</span>
                </div>
                <Brand brandStyle="branstyle--2" brands={[
                    {image:"/assets/images/brand/brand-01.png",altText:"PDRM"},
                    {image:"/assets/images/brand/brand-02.png",altText:"SUK Kedah"},
                    {image:"/assets/images/brand/brand-03.png",altText:"Lembaga Getah Malaysia"},
                    // {image:"/assets/images/brand/brand-04.png",altText:"U Mobile"},
                    // {image:"/assets/images/brand/brand-05.png",altText:"Geoinfo Services Sdn Bhd"},
                    // {image:"/assets/images/brand/brand-06.png",altText:"Handal"},
                ]}/>
            </div>
            {/* End Service Area  */}

            {/*        /!* Start Service Area *!/*/}
            <div className="service-wrapper service-white" style={{
                padding: '100px 50px'
            }}>
                <Services
                    serviceList={ServiceList}
                    title='One Stop Solution'
                />
            </div>
            {/*        /!* End Service Area *!/*/}
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Bullet
                    image='/assets/images/landing/server-room.jpg'
                    title='Why Automate?'
                    subTitle='Automation saves human resources and reduces human error by allowing you to be effective and efficient with:'
                    list={['Processing Data', 'Cleaning Data', 'Migration', 'Integration']}
                />
            </div>
            <div
                className="align-items-center bg_color--5"
                style={{
                    padding: '100px 50px'
                }}
            >
                <Platform
                    image='/assets/images/landing/scripts.jpg'
                    title='What are scripts?'
                    description='Scripts are headless console apps that run in the background that automate specific tasks that are tailored to an individual organizations needs.'
                    ctaTitle='More about Automation'
                    ctaLink='https://www.ibm.com/topics/automation'
                />
            </div>
            <div
                className="align-items-center bg_color--1"
                style={{
                    padding: '100px 50px'
                }}
                id='contact'
            >
                <Contact
                    title='Schedule a free consultation'
                    description={
                        <span>
                        Jump on a quick zoom call with us, and we will discuss your requirements and propose a few solutions.
                        Upon confirmation we will start development on your scripts based upon the pre-agreed scope and specifications.
                        <br/> <br/>
                        Then within the agreed upon timeframe we will prepare for handover and training for your staff.
                        Lastly a quick session to summarise the deployment and answer any questions you or your company may have.
                    </span>
                    }
                    phoneTrigger='AW-620745259/nRKICKL49vACEKuk_6cC'
                    emailTrigger='AW-620745259/o4mRCNeVzPACEKuk_6cC'
                    formTrigger='AW-620745259/IyNECPuP5OUCEKuk_6cC'
                    isLandingPage={keyword}
                />
            </div>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer
                ctaTitle={`From A to Z${promoActive ? ' from only RM20,000' : ''}`}
            />

        </div>
    )
}
export default ScriptAutomation;
