import React from "react";

const Platform = ({title, image, description, ctaTitle, ctaLink}) => {
    return (
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src={image} alt="About Images"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <p className="description" style={{color: '#f3f3f3', marginBottom: 35}}>{description}</p>
                                </div>
                                <a className="rn-button-style--2 btn-secondary-color" href={ctaLink} target="_blank"  rel="noopener noreferrer">{ctaTitle}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Platform;