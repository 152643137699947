import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaYoutube} from "react-icons/fa";
const logoUrl = <img src="/assets/images/logo/logo-transparent.png" alt="ZedPro" />;

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/zedproinc'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/zedpro/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/zedpro.me'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/zed_says'},
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCVlgBH2HVt8sQABtUjsnDpg'},
]

class Footer extends Component{
    render(){
        const year = new Date().getFullYear()
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-620745259');
        function gtag_phone_trigger() {
                gtag('event', 'conversion', {
                    'send_to': 'AW-620745259/nRKICKL49vACEKuk_6cC',
                });
                _mtm.push({"event": "phone_trigger"});
                return true;
            };
        function gtag_email_trigger() {
                gtag('event', 'conversion', {
                    'send_to': 'AW-620745259/o4mRCNeVzPACEKuk_6cC',
                });
                _mtm.push({"event": "email_trigger"});
                return true;
            };
        return(
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Call to Action Area  */}
                    <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                    <div className="inner">
                                        <h2 className="text-white mb--0">Schedule a free consultation, Let's build something.</h2>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                                    <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                                        <Link className="btn-default btn-large btn-border btn-opacity" to="/contact">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Call to Action Area  */}

                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                        </div>
                                        <p>Copyright © {year} <Link to="/">ZedPro Development</Link> Company No: 202203172244 (003414099-T) Ready for profitable cooperation & collaboration. All rights reserved.</p>
                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
                                    <div className="footer-link">
                                        <h4>Quick Link</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/aichatbot">AiChatbot</Link></li>
                                            <li><Link to="/techz">TechZ.io</Link></li>
                                            <li><Link to="/muhajirclinic">MuhajirClinic</Link></li>
                                            <li><Link to="/littlesteps">LittleSteps</Link></li>
                                            <li><Link to="/todoapp">TodoApp</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Zed Pro</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/portfolio">Portfolio</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><Link to="/blog">Blog</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Contact Us</h4>
                                        <ul className="ft-link">
                                            <li>Email: <a href="mailto:contact@zedpro.me" onClick={gtag_email_trigger}>contact@zedpro.me</a></li>
                                            <li>Email: <a href="mailto:help@zedpro.me" onClick={gtag_email_trigger}>help@zedpro.me</a></li>
                                            <li>Phone: <a href="tel:+60104448626" onClick={gtag_phone_trigger}>+60104448626</a></li>
                                            <li>Phone: <a href="tel:+60321819723" onClick={gtag_phone_trigger}>+60321819723</a></li>
                                            <li><a target='_blank noopener noreferrer' onClick={gtag_phone_trigger} href="https://wa.me/60104448626?text=Hey%20ZedPro%20Development%2C%20I%20am%20interested%20in%20your%20web%2Fsoftware%2Fmobile%20development%20services%2C%20please%20contact%20me%20as%20soon%20as%20you%20can."><img alt="Chat on WhatsApp" src="/assets/images/whatsapp/WhatsAppButtonBlackMedium.png" /></a></li>
                                        </ul>

                                        <div className="social-share-inner mt--20">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
