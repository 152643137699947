import React from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Link } from 'react-router-dom';

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import Brand from "../elements/Brand";

const Home = () => {
    const PostList = BlogContent.slice(0 , 3);
    const date1 = new Date("05/12/2018");
    const date2 = new Date();
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const yearsSince = Math.floor(Difference_In_Days / 365)
    var _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location]);
    _paq.push(['setDocumentTitle', "Web Design & Development"]);
    _paq.push(['trackPageView']);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Web, Mobile, Software"/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <SliderOne />
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area ptb--120 bg_color--1">
                <About />
            </div>
            {/* End About Area */}

            {/* Start Service Area  */}
            <div id='services' className="service-area ptb--120 bg_color--5">
                <div className="container">
                    <ServiceTwo />
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Portfolio Area */}
            <div className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55">
                    <Portfolio />
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">Expert growth</span>
                                <h2 className="title">Our Journey</h2>
                                <p className="description">How far we have come over the last {yearsSince} years.</p>
                            </div>
                        </div>
                    </div>
                    <CounterOne  Difference_In_Days={Difference_In_Days}/>
                </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Brand Area */}
            <div className="rn-brand-area bg_color--1 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section-title text-center mb--30">
                                <span className="subtitle">Clients</span>
                                <h2 className="title">Organizations worked with</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Brand brandStyle="branstyle--2" brands={[
                                {image:"/assets/images/brand/brand-01.png",altText:"PDRM"},
                                {image:"/assets/images/brand/brand-02.png",altText:"SUK Kedah"},
                                {image:"/assets/images/brand/brand-03.png",altText:"Lembaga Getah Malaysia"},
                                {image:"/assets/images/brand/brand-04.png",altText:"U Mobile"},
                                {image:"/assets/images/brand/brand-05.png",altText:"Geoinfo Services Sdn Bhd"},
                                {image:"/assets/images/brand/brand-06.png",altText:"Handal"},
                            ]}/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--1 ptb--120">
                <div className="container">
                    <Testimonial />
                </div>
            </div>
            {/* End Testimonial Area */}

            {/* Start Blog Area */}
            <div className="rn-blog-area ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section-title text-center">
                                <span className="subtitle">ZedPro Updates</span>
                                <h2 className="title">Latest News</h2>
                                <p className="description">Check out our tech blog, <br /> filled with plenty of tutorials and guides.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--30 mt_sm--40">
                        {PostList.map((value , i ) => (
                            <div className="col-lg-4 col-md-6 col-12 mt--30" key={i}>
                                <div className="im_box">
                                    <div className="thumbnail">
                                        <Link to={value.slug}>
                                            <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="content_heading">
                                                <div className="category_list">
                                                    <Link to={value.slug}>{value.category}</Link>
                                                </div>
                                                <h4 className="title">
                                                    <Link to={value.slug}>{value.title}</Link>
                                                </h4>
                                            </div>
                                            <div className="content_footer">
                                                <Link to={value.slug} className="rn-btn btn-opacity">Read More</Link>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to={value.slug}></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Blog Area */}

            {/* End Brand Area */}
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </div>
    )
}
export default Home;
