import React, { Component } from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Design & Responsiveness',
        description: 'With modern design languages and a responsive code, you can be sure it will run well on any device with any screen size.'
    },
    {
        icon: <FiCast />,
        title: 'Creativity & Innovation',
        description: 'We take messures to ensure our code is not only creative but also highly innovative, finding new ways to get the job done.'
    },
    {
        icon: <FiMap />,
        title: 'Web & App Development',
        description: 'Whether you require a web or desktop app, we have the expertise to ensure they not only work but work well together.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service-45 service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;