import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} | Development & Deployment | Kuala Lumpur </title>
                    <meta name="robots" content="index, follow" />
                    <meta name="description" content="Responsive & highly customizable websites. One-stop solutions for Web Design & Development, graphic, SEO, social media, audio/video design facility." />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-620745259"></script>
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
