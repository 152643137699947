import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-3.jpg" alt="Muhajir Clinic" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2.jpg" alt="FutureCloud" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-1.jpg" alt="AiPro" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="TechZ" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="LittleSteps" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="HopeSpring" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="EnglishQuill" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="Todo" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="FHMalaysia" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-10.jpg" alt="ThinkAt" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-11.jpg" alt="Cushionflute" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-12.jpg" alt="Loobamind" />;
const Portfolio_image13 = <img src="/assets/images/portfolio/portfolio-13.jpg" alt="ZedPro English Dictionary" />;
const Portfolio_image14 = <img src="/assets/images/portfolio/portfolio-14.jpg" alt="SweetQUOTES" />;
const Portfolio_image15 = <img src="/assets/images/portfolio/portfolio-15.jpg" alt="BE Lifestyles" />;
const Portfolio_image16 = <img src="/assets/images/portfolio/portfolio-16.jpg" alt="VERDAS" />;
const Portfolio_image17 = <img src="/assets/images/portfolio/arcgis-portfolio-pdrm.jpg" alt="PDRM ArcGIS Enterprise Deployment" />;
const Portfolio_image18 = <img src="/assets/images/portfolio/arcgis-portfolio-lgm.jpg" alt="LGM ArcGIS Enterprise Deployment" />;
const Portfolio_image19 = <img src="/assets/images/portfolio/arcgis-portfolio-suk-kedah.jpg" alt="SUK Kedah ArcGIS Enterprise Deployment" />;


const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Web Design',
        title: 'MuhajirClinic.com',
        description: 'A website for a free clinic run by a charity NGO in serdang.',
        url : "/muhajirclinic"
    },
    {
        image: Portfolio_image2,
        category: 'Private Cloud',
        title: 'FutureCloud.io',
        description: 'A private cloud server with military grade encryption.',
        url : "/futurecloud"
    },
    {
        image: Portfolio_image3,
        category: 'Machine Learning',
        title: 'AiChatbot',
        description: 'A simple rudementary chatbot with a fresh wit and carefree charm.',
        url : "/aichatbot"
    },
    {
        image: Portfolio_image4,
        category: 'Web Design',
        title: 'TechZ.io',
        description: 'A tech blog with articles & tutorials.',
        url: "/techz"
    },
    {
        image: Portfolio_image5,
        category: 'Web Design',
        title: 'Littlesteps.my',
        description: 'A wordpress website for a Malaysian charity.',
        url: "/littlesteps"
    },
    {
        image: Portfolio_image6,
        category: 'Web Design',
        title: 'HopeSpring',
        description: 'A wordpress website for a Malaysian charity.',
        url: "/hopespring"
    },
    {
        image: Portfolio_image7,
        category: 'Web Design',
        title: 'EnglishQuill.com',
        description: 'A wordpress website for a Malaysian charity.',
        url: "/englishquill"
    },
    {
        image: Portfolio_image8,
        category: 'Web App',
        title: 'TodoApp',
        description: 'Made with ReactJS, and the Django REST Framework.',
        url: "/todoapp"
    },
    {
        image: Portfolio_image9,
        category: 'Web Design',
        title: 'FHMalaysia.com',
        description: 'A website for a public relations firm in Petaling Jaya.',
        url : "/fhmalaysia"
    },
    {
        image: Portfolio_image10,
        category: 'Web Design',
        title: 'ThinkAt.my',
        description: 'A website for a Corporate Finance Adviser in Kuala Lumpur.',
        url : "/thinkat"
    },
    {
        image: Portfolio_image11,
        category: 'Web Design',
        title: 'Cushionflute.com',
        description: 'A website for a manufacturing company in Australia.',
        url : "/cushionflute"
    },
    {
        image: Portfolio_image12,
        category: 'Web Design',
        title: 'Loobamind.com',
        description: 'A website for an english language center.',
        url : "/loobamind"
    },
    {
        image: Portfolio_image13,
        category: 'Web App',
        title: 'zDict',
        description: 'ZedPro English Language Dictionary web app',
        url : "/zdict"
    },
    {
        image: Portfolio_image14,
        category: 'Web App',
        title: 'SweetQUOTES',
        description: 'A web app to generate sweet things to say.',
        url : "/sweetquotes"
    },
    {
        image: Portfolio_image15,
        category: 'Web Design',
        title: 'BE-Lifestyles.com',
        description: 'A website for a premium furniture rental company.',
        url : "/belifestyles"
    },
    {
        image: Portfolio_image16,
        category: 'Web App',
        title: 'VERDAS',
        description: 'A custom human resource platform.',
        url : "/verdas"
    },
    {
        image: Portfolio_image17,
        category: 'ArcGIS Enterprise',
        title: 'PDRM Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/pdrm-software-deployment"
    },
    {
        image: Portfolio_image18,
        category: 'ArcGIS Enterprise',
        title: 'LGM Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/lgm-software-deployment"
    },
    {
        image: Portfolio_image19,
        category: 'ArcGIS Enterprise',
        title: 'SUK Kedah Software Deployment',
        description: 'Industry leading GIS software.',
        url : "/suk-kedah-software-deployment"
    },
]

class PortfolioList extends Component{
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', "Portfolio"]);
            _paq.push(['trackPageView']);
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice();
        return(
            <React.Fragment>
                {list.reverse().map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.url}>
                                        {value.image}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.url}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.url}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.url}></Link>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;
