import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class HTTPStatusCodes extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'HTTP Status Codes']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='HTTP Status Codes' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> HTTP Status Codes</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 28, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h2>What are HTTP Status Codes?</h2>
                                        <p>HTTP status codes are a response that is issued by a server in response to a client’s request for information. This in turn lets the client know if the status of the intended. The first digit of the status specifies one of the five types of responses. </p>
                                        <p>I'm sure you are familiar with the popular error “404 Page not found”. There are many more HTTP status codes that exist. While the list below is a complete list of the official HTTP status codes, there are a few unofficial status codes that are not listed.</p>
                                        <p>HTTP Status Codes are contained within an official registry that is maintained by the <a aria-label="undefined (opens in a new tab)" href="https://www.iana.org/" target="_blank" rel="noreferrer noopener nofollow">Internet Assigned Numbers Authority</a> (IANA).</p>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/campaign-creators-OGOWDVLbMSc-unsplash.jpg" alt="Marketplace"/>
                                            </div>
                                        <h3 className="mt--40"><em><strong>The Different types of HTTP Status Codes</strong></em></h3>
                                        <p>There are 5 categories of HTTP status codes. The first digit will let you know the type of response. While the last two goes into more detail within that response category.</p>
                                        <ul><li><em><strong>1xx informational response</strong></em>&nbsp;– the request was received, continuing process</li><li><em><strong>2xx successful</strong></em>&nbsp;– the request was successfully received, understood, and accepted</li><li><em><strong>3xx redirection</strong></em>&nbsp;– further action needs to be taken in order to complete the request</li><li><em><strong>4xx client error</strong></em>&nbsp;– the request contains bad syntax or cannot be fulfilled</li><li><em><strong>5xx server error</strong></em>&nbsp;– the server failed to fulfill an apparently valid request</li></ul>
                                        <h4><strong>HTTP Status Codes: 1xx informational response</strong></h4>
                                        <p>Indicative of a request that is received and understood, the server issues this response on a provisional basis&nbsp;to let the client know that processing is done. This tells the client to wait for the next step. This message only consists of a status line and optional header fields and ends with an empty line.</p>
                                        <h5><strong>100 Continue</strong></h5>
                                        <p>The request headers have been received by the server and the client should send the request body should there be one to send. This is useful as sending a large request body after the server has rejected your request due to inappropriate headers would be a waste. If an error code is returned the client should not send the request body. Such errors fall within the 400 status code category, which we will touch on later in this article.</p>
                                        <h5><strong>101 Switching Protocols</strong></h5>
                                        <p>The client has requested the server to switch protocols to which the server has agreed. This means that the server has understood the request. The server will generate an Upgrade header field in the response after the empty line at the end of the 101 response that indicates the protocols that will be immediately switched to.</p>
                                        <h5><strong>102 Processing (<a href="https://www.wikiwand.com/en/WebDAV" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">WebDAV</a>; <a href="https://tools.ietf.org/html/rfc2518" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 2518</a>)</strong></h5>
                                        <p>As a WebDAV request involves file operations it may contain many sub-requests that require a long time to complete. This code lets you know that the server is processing the request that is has received but is unable to provide a response just yet.</p>
                                        <h5><strong>103 Early Hints (RFC 8297)</strong></h5>
                                        <p>This informational response code tells the client that final responses that include header fields are likely to come. This is often used to improve performance by utilizing a link header that contains relevant “preload” information. Allowing the client to start fetching the specified resource.</p>
                                        <h4><strong>HTTP Status Codes: 2xx Success</strong></h4>
                                        <p>This category indicates that the server has processed the request and it has been received, understood, and accepted. Essentially it means, the request has been successful.</p>
                                        <h5><strong>200 OK</strong></h5>
                                        <p>This status code is the traditional response for a successful HTTP request. The specific response will depend on whether the GET or POST method was used. The response then will either contain an entity corresponding to the request, or the result of the action.</p>
                                        <h5><strong>201 Created</strong></h5>
                                        <p>The request has been fulfilled by the server and it has resulted in the creation of a new resource.</p>
                                        <h5><strong>202 Accepted</strong></h5>
                                        <p>The server has accepted the request for processing, but it has not yet been completed.</p>
                                        <h5><strong>203 Non-Authoritative Information</strong></h5>
                                        <p>This is sent from a transforming proxy that is returning a modified version of the 200 OK response from the origin server.</p>
                                        <h5><strong>204 No Content</strong></h5>
                                        <p>The request was successfully processed by the server, however, no content is being returned.</p>
                                        <h5><strong>205 Reset Content</strong></h5>
                                        <p>The request was successfully processed by the server and requests the client to reset its document view, however no content is being returned.</p>
                                        <h5><strong>206 Partial Content (<a href="https://tools.ietf.org/html/rfc7233" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">RFC 7233</a>)</strong></h5>
                                        <p>Due to a range header sent by the client only part of the resource is being delivered by the server. This header is used by HTTP clients to enable resuming or splitting a download into multiple simultaneous streams.</p>
                                        <h5><strong>207 Multi-Status (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <a href="https://tools.ietf.org/html/rfc7233" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 4918</a>)</strong></h5>
                                        <p>This is used when an XML message is sent by the server that contains a number of separate response codes. This depends on the number of sub-requests made.</p>
                                        <h5><strong>208 Already Reported (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <a href="https://tools.ietf.org/html/rfc5842" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 5842</a>)</strong></h5>
                                        <p>This is usually included in a 207 Multi-Status code. Indicating that the members of a DAV binding have already been enumerated in a preceding part of the response and will not be included again.</p>
                                        <h5><strong>226 IM Used (<a href="https://tools.ietf.org/html/rfc3229" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 3229</a>)</strong></h5>
                                        <p>This lets the client know that the request for a resource has been fulfilled by the server. It is a representation of the result of one or more instance-manipulations that are applied to the current instance.</p>
                                        <h4><strong>HTTP Status Codes: 3xx Redirection</strong></h4>
                                        <p>This type of status code indicates that the client must take another action to complete the desired request. It is typically used in <a href="https://www.wikiwand.com/en/URL_redirection" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">URL redirection</a>.</p>
                                        <p>Additional action requiring no user interaction can be carried out by a user agent only if the method used in the second request is GET or HEAD. A user agent may automatically redirect a request. It should also detect and intervene to prevent cyclical redirects.</p>
                                        <h5><strong>300 Multiple Choices</strong></h5>
                                        <p>This indicates to the client that multiple options are available for the user agent to choose from. Typically this choice is done via <a href="https://www.wikiwand.com/en/Content_negotiation#Agent-driven" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">agent-driven content negotiation</a>.</p>
                                        <h5><strong>301 Moved Permanently</strong></h5>
                                        <p>This informs the client that this and all future request should be directed to a specified URI.</p>
                                        <h5><strong>302 Found (Previously “Moved temporarily”)</strong></h5>
                                        <p>Informs the client to browse another URL. This code has been superseded by 303 and 307.</p>
                                        <h5><strong>303 See Other</strong></h5>
                                        <p>The requested response can be found under another URI using the GET method. When received in response to a POST/PUT/DELETE, the client should presume that the server has received the data and should issue a new GET request to the specified URI.</p>
                                        <h5><strong>304 Not Modified</strong>  (<a href="https://tools.ietf.org/html/rfc7232" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7232</a>)</h5>
                                        <p>This status code is an implicit redirection to an already cached resource. This code tells the client that the requested resource has not been modified and is already present with the client. This code is used when the cached copy of a particular resource that has been requested is up to date with the server.</p>
                                        <h5><strong>305 Use Proxy</strong></h5>
                                        <p>The resource requested is only available via a proxy, for which the address is provided within the response. Due to security reasons, many browsers do not obey this response code.</p>
                                        <h5><strong>306 Switch Proxy</strong></h5>
                                        <p>This is no longer used, it originally meant each request subsequently should use the proxy that is specified within the 306 response.</p>
                                        <h5><strong>307 Temporary Redirect</strong></h5>
                                        <p>The request in this specific case should be repeated with a different URI; however, any future request should use the original URI. This is different than the way it was historically implemented using 302. The request method is not allowed to change when reissuing the original request. For example, a GET request will be repeated as another GET request.</p>
                                        <h5><strong>308 Permanent Redirect (<a href="https://tools.ietf.org/html/rfc7538" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7538</a>)</strong></h5>
                                        <p>This informs the client that this and all future requests should be directed to a specified URI. The HTTP method is not allowed to change similar to 307, unlike 301 and 302. An example of this would be submitting a form to a 308 resource that can still continue smoothly.</p>
                                        <h4><strong>HTTP Status Codes: 4xx Client errors</strong></h4>
                                        <p>A status code that starts with 4xx is indicative of an error that is caused by the client. Unless responding to a HEAD request, an entity containing an explanation of the error situation should be included by the server. This explanation should include if the error is permanent or temporary. These status codes are applicable to any request method. The browser should display any included entity to the user.</p>
                                        <h5><strong>400 Bad Request</strong></h5>
                                        <p>Due to an apparent client error, the server cannot or will not process the request. This might be because of a malformed request syntax, a size too large, invalid request message framing, or deceptive request routing.</p>
                                        <h5><strong>401 Unauthorized (<a href="https://tools.ietf.org/html/rfc7235" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7235</a>)</strong></h5>
                                        <p>This code is specifically to be used when authentication is required by a website and has either failed or yet to be provided. A WWW-Authentication header field containing a challenge applicable to the requested resource must be included in the response. In general 401 means, the user is unauthorized and thus is not allow access to the resource requested.</p>
                                        <h5><strong>402 Payment Required</strong></h5>
                                        <p>Currently not implemented and is reserved for future use. Its original intention was to be used as part of some form of payment solution. However, this has yet to be implemented. Some companies use it to indicate specific payment-related errors. Such as when a daily request has been exceeded, when there are insufficient funds, when due to a payment issue a website has been temporarily disabled, or when a payment has been blocked due to a fraudulent nature.</p>
                                        <h5><strong>403 Forbidden</strong></h5>
                                        <p>The request was understood by the server and contained valid data, however, the action is being refused by the server. This could be due to several reasons including but not limited to a permissions error or the server trying to perform a prohibited action. Usually, this code is provided when authentication provided but the server does not accept it. Subsequent requests by the client should not be performed.</p>
                                        <h5><strong>404 Not Found</strong></h5>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/erik-mclean-Rg-fYTQ5Rgw-unsplash.jpg" alt="404 Not Found"/>
                                            </div>
                                        <p className="mt--40">The most common and most popular error code. It informs the client that the requested resource could not be found by the server. This may be temporary or permanent, thus subsequent requests by the client are allowed.</p>
                                        <h5><strong>405 Method Not Allowed</strong></h5>
                                        <p>The method used to request the resource is not supported. An example of this is when a GET request is used but the server requires a POST request instead.</p>
                                        <h5><strong>406 Not Acceptable</strong></h5>
                                        <p>The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.</p>
                                        <h5><strong>407 Proxy Authentication Required (<a href="https://tools.ietf.org/html/rfc7235" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7235</a>)</strong></h5>
                                        <p>This status code is generated when authentication must be done by the client with the <a href="https://www.wikiwand.com/en/Proxy_server" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">proxy</a> first.</p>
                                        <h5><strong>408 Request Timeout</strong></h5>
                                        <p>When the server times out while waiting for a request it will respond with this error code. This happens when the client does not produce a request within the time frame that the server is willing to wait.</p>
                                        <h5><strong>409 Conflict</strong></h5>
                                        <p>This happens when there is a conflict in the current state of the resource, thus the server is unable to process the request. Such as an edit conflict between multiple simultaneous updates.</p>
                                        <h5><strong>410 Gone</strong></h5>
                                        <p>This is used when the server would like to instruct and inform the client that the resource requested is no longer available and will never be available again. Once the client receives this response it should purge the resource from its cache. This is often used by search engines. In practice, however, a 404 is used instead.</p>
                                        <h5><strong>411 Length Required</strong></h5>
                                        <p>When a resource that has been requested requires the length of its content to be specified, but the client has not done so, the server will return a 411 error.</p>
                                        <h5><strong>412 Precondition Failed (<a href="https://tools.ietf.org/html/rfc7232" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7232</a>)</strong></h5>
                                        <p>One of the preconditions set by the requester in the header field is not met by the server. Thus this error code is returned.</p>
                                        <h5><strong>413 Payload Too Large (<a href="https://tools.ietf.org/html/rfc7231" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7231</a>)</strong></h5>
                                        <p>The server is unwilling or able to process the request due to it being too large. This used to be called “Request Entity Too Large”.</p>
                                        <h5><strong>414 URI Too Long (<strong><a href="https://tools.ietf.org/html/rfc7231" target="_blank" rel="noreferrer noopener">RFC 7231</a></strong>)</strong></h5>
                                        <p>This usually happens when too much data is being encoded as a query-string of a GET request. This means that the URI provided in the GET request is too long for the server to process. In such cases, the method of the request should be changed from GET to POST. Previously called “Request-URI Too Long”.</p>
                                        <h5><strong>415 Unsupported Media Type (<strong><a href="https://tools.ietf.org/html/rfc7231" target="_blank" rel="noreferrer noopener">RFC 7231</a></strong>)</strong></h5>
                                        <p>The entity requested has a media type that is unsupported by the server or the resource. Such as when a client tries to upload an image that has the wrong format.</p>
                                        <h5><strong>416 Ranged Not Satisfied (<a href="https://tools.ietf.org/html/rfc7233" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7233</a>)</strong></h5>
                                        <p>When a server cannot supply the portion of the file requested by the client. Such as if the client requests a part of the file that lies beyond the end of said file.</p>
                                        <h5><strong>417 Expectation Failed</strong></h5>
                                        <p>The status code means the requirements of the Expect request-header field cannot be met by the server.</p>
                                        <h5><strong>418 I’m a teapot (<a href="https://tools.ietf.org/html/rfc2324" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 2324</a>, <a href="https://tools.ietf.org/html/rfc7168" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7168</a>)</strong></h5>
                                        <p>This code was defined as an April Fools’ joke in 1998 by <a href="https://www.wikiwand.com/en/Internet_Engineering_Task_Force" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">IETF</a> in <a aria-label="undefined (opens in a new tab)" href="https://tools.ietf.org/html/rfc2324" target="_blank" rel="noreferrer noopener nofollow">RFC 2324</a>. It stands for <a href="https://www.wikiwand.com/en/Hyper_Text_Coffee_Pot_Control_Protocol" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Hyper Text Coffee Pot Control Protocol</a>, and it's not expected to be used. This code is specified by the RFC to be returned by teapots requested to brew coffee.</p>
                                        <h5><strong>421 Misdirected Request (<a href="https://tools.ietf.org/html/rfc7540">RFC 7540</a>)</strong></h5>
                                        <p>A response is not able to be produced because it has been directed at a server that is unable to handle the request.</p>
                                        <h5><strong>422 Unprocessable Entity (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <a href="https://tools.ietf.org/html/rfc4918" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 4918</a>)</strong></h5>
                                        <p>Due to semantic errors, even though the request is well-formed it is not able to be followed.</p>
                                        <h5><strong>423 Locked (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <strong><a href="https://tools.ietf.org/html/rfc4918" target="_blank" rel="noreferrer noopener">RFC 4918</a></strong>)</strong></h5>
                                        <p>This response code is generated when access to the resource is being locked.</p>
                                        <h5><strong>424 Failed Dependency (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <strong><a href="https://tools.ietf.org/html/rfc4918" target="_blank" rel="noreferrer noopener">RFC 4918</a></strong>)</strong></h5>
                                        <p>Another request which was a dependency has failed which in turn caused this request to fail.</p>
                                        <h5><strong>425 Too Early (<a href="https://tools.ietf.org/html/rfc8470" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 8470</a>)</strong></h5>
                                        <p>This response code is returned when the server is not willing to risk processing a request that might be replayed.</p>
                                        <h5><strong>426 Upgrade Required</strong></h5>
                                        <p>When this error code is received the client should switch to a different protocol, which is specified in the Upgrade header field.</p>
                                        <h5><strong>428 Precondition Required (<a href="https://tools.ietf.org/html/rfc6585" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 6585</a>)</strong></h5>
                                        <p>A specific condition is required from the origin server for the request to be completed. This is intended to prevent the “lost update” problem. This is primarily done to avoid a conflict on the server.</p>
                                        <h5><strong>429 Too Many Request (<strong><a href="https://tools.ietf.org/html/rfc6585" target="_blank" rel="noreferrer noopener">RFC 6585</a></strong>)</strong></h5>
                                        <p>Too many requests have been sent by the client to the server within a specified amount of time. Used primarily with rate-limiting schemes.</p>
                                        <h5><strong>431 Request Header Fields Too Large (<strong><a href="https://tools.ietf.org/html/rfc6585" target="_blank" rel="noreferrer noopener">RFC 6585</a></strong>)</strong></h5>
                                        <p>If either the individual header field or the header fields collectively are too large, then the server will be unwilling to process the request.</p>
                                        <h5><strong>451 <a href="https://www.wikiwand.com/en/HTTP_451" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Unavailable For Legal Reasons</a> (<a href="https://tools.ietf.org/html/rfc7725" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 7725</a>)</strong></h5>
                                        <p>For this HTTP Status Code, the sever is complying with a legal demand to deny access to a particular or set of resources. This happens when the server operator has been presented with either a legal demand or a court order. The number 451 was chosen as a reference to the novel <a href="https://www.wikiwand.com/en/Fahrenheit_451" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">Fahrenheit 451</a>.</p>
                                        <h4><strong>HTTP Status Codes: 5xx Server errors</strong></h4>
                                        <div className="thumbnail-normal">
                                            <img src="/assets/images/blog/5XX-Errors.jpg" alt="5XX Errors"/>
                                            </div>
                                        <p className="mt--40">This category of error codes occurs when the server fails to fulfill a request sent by a client. Response status codes that start with the digit “5” indicate that the server is aware that it has experienced an error or has failed to perform the request at hand.</p>
                                        <p>Unless responding to a HEAD request, an entity containing an explanation of the error situation should be included by the server. This explanation should include if the error is permanent or temporary. These status codes are applicable to any request method. The browser should display any included entity to the user.</p>
                                        <h5><strong>500 Internal Server Error</strong></h5>
                                        <p>This is a generic error message, it is provided when an unexpected situation was met and there isn't a more suitable message available.</p>
                                        <h5><strong>501 Not Implemented</strong></h5>
                                        <p>This response is provided when the server either lacks the ability to fulfill the request or it fails to recognize the request method. This more than often implies future availability.</p>
                                        <h5><strong>502 Bad Gateway</strong></h5>
                                        <p>This is usually given when the server is acting as a gateway or proxy and is forwarding an invalid response from an upstream server.</p>
                                        <h5><strong>503 Service Unavailable</strong></h5>
                                        <p>The request cannot be handled by the server, usually due to it being overloaded or down for maintenance. Such an error is generally temporary.</p>
                                        <h5><strong>504 Gateway Timeout</strong></h5>
                                        <p>The upstream server did not respond in a timely manner. Therefore the original server or gateway provides this response.</p>
                                        <h5><strong>505 HTTP Version Not Supported</strong></h5>
                                        <p>This response code is sent by the server when the HTTP protocol version used within the request is not supported by the server.</p>
                                        <h5><strong>506 Variant Also Negotiates (<a href="https://tools.ietf.org/html/rfc2295" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 2295</a>)</strong></h5>
                                        <p>The client’s request has caused a closed-loop otherwise known as a circular reference. Which has resulted due to Transparent content negotiation.</p>
                                        <h5><strong>507 Insufficient Storage (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <a href="https://tools.ietf.org/html/rfc4918" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 4918</a>)</strong></h5>
                                        <p>The server has run out of storage and cannot store the representation needed to complete the request.</p>
                                        <h5><strong>508 Loop Detected (<strong><a href="https://www.wikiwand.com/en/WebDAV" target="_blank" rel="noreferrer noopener">WebDAV</a></strong>; <a href="https://tools.ietf.org/html/rfc5842" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 5842</a>)</strong></h5>
                                        <p>This HTTP Status Code is sent when an infinite loop is detected while processing a request that the client has sent. This status code is sent instead of 208 Already Reported.</p>
                                        <h5><strong>510 Not Extended (<a href="https://tools.ietf.org/html/rfc2774" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 2774</a>)</strong></h5>
                                        <p>The request that the client has sent is not sufficient and a further extension is required before the server can fulfill the request.</p>
                                        <h5><strong>511 Network Authentication Required (<a href="https://tools.ietf.org/html/rfc6585" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener nofollow">RFC 6585</a>)</strong></h5>
                                        <p>Authentication is required by the client to gain network access. Intercepting proxies use this to control access to the network in question. Usually found in captive portals where the user needs to agree to the Terms of Service before being granted full Internet access via public Wi-Fi Hotspots.</p>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default HTTPStatusCodes;