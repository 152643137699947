import React from "react";
import Countdown from "react-countdown";
import {useDispatch} from "react-redux";
import {userActions} from "../../store/user";
import {Link} from "react-router-dom";

const Hero = ({keyword, title, price, description, promoActive, setPromoActive, hook, page}) => {
    const dispatch = useDispatch();
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            setPromoActive(false);
            return <h2 className="title">Promo has ended</h2>
        } else {
            const hourPrefix =  (days===0 && hours.toString().length===1) && '0'
            // Render a countdown
            return <h2 className="title">Promo ends in <span style={{color: 'red'}}>{hourPrefix}{hours+(days*24)}:{minutes.toString().length===1 && '0'}{minutes}:{seconds.toString().length===1 && '0'}{seconds}</span></h2>
        }
    };
    const date = new Date();
    let promoEndDate = parseInt((document.cookie.split(';').filter(cookie => (cookie.split('=')[0])?.includes('promoEndDate')))[0]?.split('=')[1])
    if(promoEndDate){
        date.setTime(promoEndDate)
        dispatch(userActions.setPromoTimestamp(promoEndDate));
    } else {
        date.setDate(date.getDate()+3)
        document.cookie = `promoEndDate=${date.getTime()};max-age=7889238`
    }
    let AltHeading;
    switch (page) {
        case "arcgis": AltHeading = "Enterprise GIS Software Malaysia";break;
        case "website": AltHeading = "Website Development Services Malaysia";break;
        case "webapp": AltHeading = "Web App Development Services Malaysia";break;
        case "serverdeployment": AltHeading = "Hardware & Software";break;
        case "script": AltHeading = "Data Processing & Cleaning";break;
        case "mobileapp": AltHeading = "Mobile App Development Services Malaysia";break;
        default: AltHeading = "Special Promotion";break;
    }
    return (
        <div className="about-area pt--150 pb--100 bg_image bg_image--1 mr-lg-5 ml-lg-5">
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-7 col-md-12 mb--50">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <div className="section-title">
                                            <span
                                                className="subtitle subtitleMod--medium subtitleCapital">{keyword ? keyword : AltHeading}</span>
                                    </div>
                                    <h2 className="title">{title}{promoActive && (
                                        <>
                                            <span>, </span>
                                            <span style={{color: 'grey', fontWeight: 'bold'}}>RM{price.toLocaleString()}</span>
                                        </>
                                    )}</h2>
                                    <p className="description" style={{color: '#f3f3f3'}}>{description} {hook}</p>
                                    {promoActive && <Countdown date={date} renderer={renderer} daysInHours/>}
                                    <div className="purchase-btn" style={{
                                        display: 'flex',
                                        gap: 15,
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Link className="rn-button-style--2 btn-secondary-color" to="/portfolio">View
                                            Portfolio</Link>
                                        <a className="rn-button-style--2 btn-primary-color" href="#contact">Inquiries</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;