import React, {
    // useState
} from "react";

const Brand = ({brandStyle, brands}) => {
    return (
        <React.Fragment>
            <ul className={`brand-list ${brandStyle}`}>
                {brands.map((brand, i) => (
                    <li key={i}>
                        <img src={brand.image} alt={brand.alt}/>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    )
}
export default Brand;