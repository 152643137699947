import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Disqus from "disqus-react";
import BlogContent from "../elements/blog/BlogContent";

class Top5EssentialPagesInAnyWebsite extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var _paq = window._paq = window._paq || [];
            _paq.push(['setCustomUrl', window.location]);
            _paq.push(['setDocumentTitle', 'Top 5 essential pages in any Website']);
            _paq.push(['trackPageView']); 
            const PostList = BlogContent.slice()
            const disqusShortname = "zedpro-me"
            const disqusConfig = PostList.map((value) => ( {
                url: "https://zedpro.me",
                identifier: value.slug,
                title: value.title
            }))
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Top 5 essential pages in any Website' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient"> Top 5 essential pages in  <br /> any Website</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Jul 31, 2020</li>
                                        <li><FiUser />Zed Pro</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />0 Likes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h3><em><strong>What are the </strong></em><em><strong>Top 5 essential pages in any Website</strong></em><em><strong>?</strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/fabian-irsara-67l-QujB14w-unsplash-2.jpg" alt="Blog Images"/>
                                            </div>
                                        <p className="mt--40">How many pages does your website need to have? Do you need a blog? How about a page telling people what you do? You must have a contact page, right? Let's dig deep into the, why, what, when, where, and how of the matter on hand. What are the top 5 essential pages in any Website? How do they help you convert visitors into customers and how are they going to help you achieve your business goals? Most importantly how are they going to help your visitors?</p>
                                        <h3><em><strong><a href="https://zedpro.me/" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">Home Page</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/home-page.jpg" alt="Blog Images"/>
                                            </div>
                                        <h5 className="mt--40"><strong>Why is it important?</strong></h5>
                                        <p>This is the main page that people who visit your domain will see. It certainly qualifies as one of the top 5 essential pages in any Website. Once loaded its a race against time to capture your visitor's attention before they bounce off to somewhere else. Designing a captivating home page is vital to achieving your goals. You want your visitors to be drawn in and go from your home page to the other pages within your website.</p>
                                        <h5><strong>What purpose does it serve?</strong></h5>
                                        <p>The purpose of your home page is to give enough information to your visitors about what your website is about. You want your visitors to stay, and these days attention is at a premium. Your home page should be interesting enough and have the right structured format to send visitors to pages that will inform and educate.</p>
                                        <h5><strong>How should it look like?</strong></h5>
                                        <p>This depends greatly on who the website is designed for. You will want to check out other websites within your niche but generally, there are best practices. Firstly you will want to have a theme for your website. You will need to choose a color scheme as well. Last but not least you must add some content.</p>
                                        <h5><strong>What type of content should I place here?</strong></h5>
                                        <p>This will determine the type of content you need to add, some websites will need more visual content while others should have more copy-writing. You may even want to add a nice video slider to immediately capture your visitor's attention. I mean at this point it's free advertising. You will need to sit down with your web designer to discuss further.</p>
                                        <h5><strong>When do you want people to see it?</strong></h5>
                                        <p>Your homepage is essentially a summary of your company and what it does. So this is the first page you want your visitors to see. If you have a page that is more popular, then you're really not doing justice to your home page. When linking to your website, this is the page those links should point to.</p>
                                        <h3><em><strong><a href="/about" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">About Page</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/about-page.jpg" alt="Blog Images"/>
                                            </div>
                                        <h5 className="mt--40"><strong>Why is it important?</strong></h5>
                                        <p>This is one of the most important pages you need to have, it tells your visitors about who you are. It helps build trust and authority, and it informs people of your company’s history. Without is visitors are skeptical. If they were to really think about it, they will start to feel like this is not the website of a real person or company. When was the last time you trusted a website that didn't have an “About Us” page? I know I would not.</p>
                                        <h5><strong>What purpose does it serve?</strong></h5>
                                        <p>As touched on about, the main purpose of this page is to inform your visitors that you are who you say you are. That you are not a scammer, you are an authentic company and this page tells them what you are all about. It builds trust and authority by letting them know of your history and other relevant details. While some people might not even read everything that is on there, if you didn't have one it would raise suspicion.</p>
                                        <h5><strong>How should it look like?</strong></h5>
                                        <p>As with all the other pages on your website, your “about us” page needs to follow the same theme, color scheme, and content style as the rest of your website. How would you feel if you visited a website and the about us page looked like it came from a different site? Would you trust a website where one page is bright and colorful where another is just monotoned? That would be a big red flag.</p>
                                        <h5><strong>What type of content should I place here?</strong></h5>
                                        <p>On your “about us” page you should place information relating to what you or your company does. History of your company, and a brief overview of how you got to where you are. Touch a little about expertise as well, that couldn’t hurt. Remember the goal here is not to sell a product or service, that comes later. Your goal is to sell confidence in yourself and your company. Basically you're trying to tell your visitors that this is who you are and this is why they should give you their business.</p>
                                        <h5><strong>When do you want people to see it?</strong></h5>
                                        <p>The people you want coming to this page are those that have already browsed your homepage and are still interested. Here you build on that interest and form a kind of bond with your visitor. You want them invested, you want them to believe in you, to believe is the things you are offering them. You could have the best product in the world but if the customer doesn't trust you, they are going to send their business elsewhere.</p>
                                        <h3><em><strong><a href="blog" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">Blog</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/blog-page.jpg" alt="Blog Images"/>
                                            </div>
                                        <h5 className="mt--40"><strong>Why is it important?</strong></h5>
                                        <p>Blogs are important for two main reasons. Firstly they are an avenue to express how well versed you are within your industry. Studies show that visitor confidence is up to 74% higher in websites that have blogs vs those who don’t. Having a blog also is a great boost to your search engine optimization. When search engines see that your website is posting content regularly it is more likely that they will rank higher.</p>
                                        <h5><strong>What purpose does it serve?</strong></h5>
                                        <p>Having a blog on your website will reduce its bounce rate. Blogs give you a fantastic avenue to express the view and expertise of yourself and your company. Each post you create will attract new visitors, and keep existing visitors from leaving. Visitors are more likely to stay longer on your website as they go from post to post. A blog increases your website's ability to convert visitors into customers.</p>
                                        <h5><strong>How should it look like?</strong></h5>
                                        <p>A blog is generally simple, that is if you use a content management system (CMS). With a CMS such as WordPress, a&nbsp; blog is literally what it comes with. You don't have to do much to set up a blog in WordPress. That being said, you should pay some attention to the structure of your blog. Categorize your post properly and don't forget to include tags in each post. This will help visitors browse content they are looking for and interested in. Lastly, you will want to add content that contains not only plain text but also engaging pictures.</p>
                                        <h5><strong>Who are you trying to convert?</strong></h5>
                                        <p>People often say don’t give away the golden goose. But we must understand that at the end of the say, by telling people how you do things, it shows them you know your stuff. Because what you do requires time and effort. Those who can’t afford your product or services were never going to be your target audience. Your target audience is those you will see the benefits of hiring you and honestly do not have the time or energy to do it themselves.</p>
                                        <h5><strong>When do you want people to see it?</strong></h5>
                                        <p>You want to display relevant posts as bait within each page, this gives your visitors an out and a flow to your website. For example when browsing the page related to a service you provide show a few eye-catching posts about it. This will help shepherd your visitors around your site. Remember, the longer they stay and browse your website the better chance you have of converting them into customers.</p>
                                        <h3><em><strong><a href="/portfolio" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">Product or Service Page</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/Service-page.jpg" alt="Service Page"/>
                                            </div>
                                        <h5 className="mt--40"><strong>Why is it important?</strong></h5>
                                        <p>Well, I’m sure, you have a general idea as to why your product or service page is important. Its the main way you monetize your website. If your web designer doesn’t build you one. FIRE HIM! This definitely is one of the top 5 essential pages in any Website. Back to the point; Essentially your whole website is made to lead visitors to this page. Here is where you explain and try to sell whatever it is you are trying to sell. If you have gotten your visitor all the way here, half the battle has been won.</p>
                                        <h5><strong>What purpose does it serve?</strong></h5>
                                        <p>How are we still on this? This page tells visitors about your product or service. While your “about us” page outlines and educates them about why they should, your “product or service” page tells them what they should be buying. Think of it as the icing on the cake, the cherry on top, basically the culmination of the visitor's journey. They have arrived, and now you have their attention so capture it.</p>
                                        <h5><strong>How should it look like?</strong></h5>
                                        <p>This page needs to have all your best promotional materials. Spend some extra money, extra time on this page. It's like a masterpiece, it should be better than all the other pages on your site. It needs to be, you need to have quality here more than anything. Ensure it is full of great pictures and excellent copywriting. Of course, don't forget to include some of the best reviews your product or service has ever gotten.</p>
                                        <h5><strong>Who are you trying to convert?</strong></h5>
                                        <p>Your target audience here is those who genuinely need your product or service. Remember you do not want to be in it for a quick buck. You want satisfied customers, as they are the ones that will keep coming back. Bad reviews from dissatisfied customers are not good for your business. Selling your product or service to someone you know will not benefit from it is also unethical. You wanna target those who really need what you are offering. Those are who you are trying to convert.</p>
                                        <h5><strong>When do you want people to see it?</strong></h5>
                                        <p>Now ideally people will visit this page after they have been to other pages and now are fully aware of what you do, why you do it, and how you got to where you are. That, however, is not always the case. Sometimes you will get people who come through search engines straight to your product page, but that’s okay. Remember this is why we touched above on the fact that this page should be a masterpiece.</p>
                                        <h3><em><strong><a href="/contact" target="_blank" aria-label="undefined (opens in a new tab)" rel="noreferrer noopener">Contact Page</a></strong></em></h3>
                                        <div className="thumbnail-normal">
                                                <img src="/assets/images/blog/contact-page.jpg" alt="Blog Images"/>
                                            </div>
                                        <h5><strong>Why is it important?</strong></h5>
                                        <p>Your customers and visitors need to be able to contact you. This may be to give you feedback, to inquire about the products and services you provide, or maybe they are just lonely. Two main pages that give you credibility and foster trust in your website and company are your “about us” and “contact” page. I mean let's just face it, a website without a contact us page is a big red flag. Like are you for real? A contact page is the most important page out of the top 5 essential pages in any Website.</p>
                                        <h5><strong>What purpose does it serve?</strong></h5>
                                        <p>Contact pages serve multiple purposes, primarily as the name suggests it provides your contact details. But it also serves to provide visitors with the location of your office or shop. This in turn solidifies to your visitors and gives them an idea about your physical location. It lets them know if you're based locally or internationally. Furthermore, it gives you a final point to convert visitors into customers by answering any questions or queries they may have.</p>
                                        <h5><strong>How should it look like?</strong></h5>
                                        <p>Contact pages shouldn't have too many words on it, you're not selling anything here. You're just providing practical information to allow your visitors and customers to get in touch with you. A contact page needs to have your phone number, email address, and physical location. Add an interactive map as well, and don’t forget your social media pages. It should be all-inclusive, friendly, and warm. You’re inviting your website visitors to reach out.</p>
                                        <h5><strong>Who are you trying to convert?</strong></h5>
                                        <p>At this stage, if your website visitors have made it to your contact page, then you have done a great job. You have real people who are genuinely interested. They are what is known as leads, that’s who you want to convert. They have spent time reading all about you, your company, and your product or service. Now you just gotta put on your warmest smile and make the sale by communicating and consulting with your customers. Help them purchase what they need.</p>
                                        <h5><strong>When do you want people to see it?</strong></h5>
                                        <p>You want your visitors to be able to see your contact page when they are ready to take it to the next step. When they feel ready to reach out and express what they need. This will naturally be different for each visitor. Some might want to contact you after just one visit, while for others it might take a few visits for them to convert. Nevertheless, do not worry, they are not going anywhere and neither are you. You are in it for the long haul.</p>
                                        <h6><em>I hope now based on what you have read, you have a better idea of what these pages do and why they are included in the list of top 5 essential pages in any Website. Of course, depending on the website you are trying to make, you may want to add even more pages. These 5 are a starting point for you to branch off from. Think I missed an important page? Let me know in the comments.</em></h6>
                                        {/* <h2>Want more information? Then schedule a free consultation</h2>
                                        <div className="purchase-btn">
                                                    <Link className="btn-transparent-large" to="/contact"><span>Contact Us</span></Link>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Blog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default Top5EssentialPagesInAnyWebsite;